import styles from './index.module.pcss';

export function ChatPreloader() {
	return (
		<div className={styles.dotLoader}>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
}
