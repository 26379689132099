import { AIChat, AISessionsList } from './dto';

const aiSessionsListExample: AISessionsList = {
	sessions: [
		{
			id: 0,
			name: 'Session name',
			created_at: 1638267989332,
		},
	],
};

const aiChatExample: AIChat = {
	session_id: 0,
	messages: [
		{
			role: 'user',
			content: 'Text request to chat',
		},
	],
	closed: false,
};

export { aiSessionsListExample, aiChatExample };
