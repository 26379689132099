import { generatePath } from 'react-router';
import { noSQLDatabasesSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { NoSQLDatabaseSampleDetails } from 'models/noSQLDatabases/dto';
import { PATHS } from 'services/router';

type Props = {
	sample: Pick<
		NoSQLDatabaseSampleDetails,
		| 'database_id'
		| 'database_name'
		| 'collection_id'
		| 'collection_name'
		| 'schema_id'
		| 'schema_name'
		| 'object_id'
	> | null;
};

function SampleHeader({ sample }: Readonly<Props>) {
	if (!sample) return null;

	const {
		database_id,
		database_name,
		collection_id,
		collection_name,
		schema_id,
		schema_name,
		object_id,
	} = sample;

	const steps = [
		...noSQLDatabasesSteps,
		{
			path: generatePath(PATHS.NOSQL_DATABASES_ITEM, {
				id: database_id,
				tab: 'summary',
			}),
			name: database_name,
		},
		{
			path: generatePath(PATHS.NOSQL_DATABASES_COLLECTION_ITEM, {
				id: collection_id,
				tab: 'summary',
			}),
			name: collection_name,
		},
		{
			path: generatePath(PATHS.NOSQL_DATABASES_SCHEMA_ITEM, {
				id: schema_id,
			}),
			name: schema_name,
		},
	];

	return (
		<Header>
			<Header.Breadcrumbs steps={steps} finalStep={object_id} />

			<Header.Title>{object_id}</Header.Title>
		</Header>
	);
}

export { SampleHeader };
