import { useCallback, useMemo } from 'react';
import Icon from 'components/Icon';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { PolicyRuleItem } from 'models/policies/dto';
import LabelRule from '../LabelRule';
import NamespaceLabelRule from '../NamespaceLabelRule';
import SimpleRule from '../SimpleRule';
import styles from './index.module.css';

type Props = {
	prefix: string;
	value: PolicyRuleItem[];
	onChange: (mutatorFn: (draft: PolicyRuleItem[]) => void) => void;
	hideAssetGroups?: boolean;
};

function ComplexRule(props: Props) {
	const { prefix, value, onChange, hideAssetGroups } = props;

	const namespaceRuleIdx = useMemo(
		() => value.findIndex((rule) => rule.type === 'namespace'),
		[value]
	);

	const assetRuleIdx = useMemo(() => value.findIndex((rule) => rule.type === 'asset'), [value]);
	const clusterRuleIdx = useMemo(() => value.findIndex((rule) => rule.type === 'cluster'), [value]);
	const assetGroupRuleIdx = useMemo(
		() => value.findIndex((rule) => rule.type === 'asset_group'),
		[value]
	);
	const serviceLabelRuleIdx = useMemo(
		() => value.findIndex((rule) => rule.type === 'label'),
		[value]
	);

	const labelRules = useMemo(() => value.filter((rule) => rule.type === 'label'), [value]);

	const namespaceLabelRules = useMemo(
		() => value.filter((rule) => rule.type === 'namespace_label'),
		[value]
	);

	const addNamespaceRule = useCallback((event) => {
		event.preventDefault();
		const newRule: PolicyRuleItem = {
			type: 'namespace',
			operator: 'is',
			key: '',
			values: [],
		};

		onChange((draft) => {
			draft.push(newRule);
		});
	}, []);

	const addAssetRule = useCallback((event) => {
		event.preventDefault();
		const newRule: PolicyRuleItem = {
			type: 'asset',
			operator: 'is',
			key: '',
			values: [],
		};

		onChange((draft) => {
			draft.push(newRule);
		});
	}, []);

	const addClusterRule = useCallback((event) => {
		event.preventDefault();
		const newRule: PolicyRuleItem = {
			type: 'cluster',
			operator: 'is',
			key: '',
			values: [],
		};

		onChange((draft) => {
			draft.push(newRule);
		});
	}, []);

	const addAssetGroupRule = useCallback((event) => {
		event.preventDefault();
		const newRule: PolicyRuleItem = {
			type: 'asset_group',
			operator: 'is',
			key: '',
			values: [],
		};

		onChange((draft) => {
			draft.push(newRule);
		});
	}, []);

	const addLabelRule = useCallback((event) => {
		event.preventDefault();
		const newRule: PolicyRuleItem = {
			type: 'label',
			operator: 'is_set',
			key: '',
			values: [],
		};

		onChange((draft) => {
			draft.push(newRule);
		});
	}, []);

	const addNamespaceLabelRule = useCallback((event) => {
		event.preventDefault();
		const newRule: PolicyRuleItem = {
			type: 'namespace_label',
			operator: 'is_set',
			key: '',
			values: [],
		};

		onChange((draft) => {
			draft.push(newRule);
		});
	}, []);

	return (
		<div className={styles.container} data-test="policy-complex-rule-row">
			<Typo component="span" variant="D/Medium/Body-S" className={styles.prefix}>
				{prefix}
			</Typo>

			<div>
				{namespaceRuleIdx !== -1 && (
					<SimpleRule
						value={value[namespaceRuleIdx]}
						onChange={function onSimpleRuleChange(mutatorFn) {
							onChange((draft) => {
								const partialState = draft[namespaceRuleIdx];
								const possibleResult = mutatorFn(partialState);
								if (possibleResult !== undefined) draft[namespaceRuleIdx] = possibleResult;
							});
						}}
						onDelete={function onSimpleRuleDelete() {
							onChange((draft) => draft.filter((_, i) => i !== namespaceRuleIdx));
						}}
					/>
				)}

				{assetRuleIdx !== -1 && (
					<SimpleRule
						prefix={namespaceRuleIdx !== -1 ? 'and' : undefined}
						value={value[assetRuleIdx]}
						onChange={function onSimpleRuleChange(mutatorFn) {
							onChange((draft) => {
								const partialState = draft[assetRuleIdx];
								const possibleResult = mutatorFn(partialState);
								if (possibleResult !== undefined) draft[assetRuleIdx] = possibleResult;
							});
						}}
						onDelete={function onSimpleRuleDelete() {
							onChange((draft) => draft.filter((_, i) => i !== assetRuleIdx));
						}}
					/>
				)}

				{assetGroupRuleIdx !== -1 && (
					<SimpleRule
						prefix={namespaceRuleIdx !== -1 || assetRuleIdx !== -1 ? 'and' : undefined}
						value={value[assetGroupRuleIdx]}
						onChange={function onSimpleRuleChange(mutatorFn) {
							onChange((draft) => {
								const partialState = draft[assetGroupRuleIdx];
								const possibleResult = mutatorFn(partialState);
								if (possibleResult !== undefined) draft[assetGroupRuleIdx] = possibleResult;
							});
						}}
						onDelete={function onSimpleRuleDelete() {
							onChange((draft) => draft.filter((_, i) => i !== assetGroupRuleIdx));
						}}
					/>
				)}

				{labelRules.map((rule, i) => {
					const labelRuleIdx = value.indexOf(rule);

					return (
						<LabelRule
							key={i}
							prefix={
								namespaceRuleIdx !== -1 || assetRuleIdx !== -1 || i > 0 || assetGroupRuleIdx !== -1
									? 'and'
									: undefined
							}
							value={rule}
							onChange={function onSimpleRuleChange(mutatorFn) {
								onChange((draft) => {
									const partialState = draft[labelRuleIdx];
									const possibleResult = mutatorFn(partialState);
									if (possibleResult !== undefined) draft[labelRuleIdx] = possibleResult;
								});
							}}
							onDelete={function onSimpleRuleDelete() {
								onChange((draft) => draft.filter((_, j) => j !== labelRuleIdx));
							}}
						/>
					);
				})}

				{namespaceLabelRules.map((rule, i) => {
					const labelRuleIdx = value.indexOf(rule);

					return (
						<NamespaceLabelRule
							key={i}
							prefix={
								namespaceRuleIdx !== -1 ||
								assetRuleIdx !== -1 ||
								i > 0 ||
								assetGroupRuleIdx !== -1 ||
								serviceLabelRuleIdx !== -1
									? 'and'
									: undefined
							}
							value={rule}
							onChange={function onSimpleRuleChange(mutatorFn) {
								onChange((draft) => {
									const partialState = draft[labelRuleIdx];
									const possibleResult = mutatorFn(partialState);
									if (possibleResult !== undefined) draft[labelRuleIdx] = possibleResult;
								});
							}}
							onDelete={function onSimpleRuleDelete() {
								onChange((draft) => draft.filter((_, j) => j !== labelRuleIdx));
							}}
						/>
					);
				})}

				{clusterRuleIdx !== -1 && (
					<SimpleRule
						prefix={
							namespaceRuleIdx !== -1 ||
							assetRuleIdx !== -1 ||
							assetGroupRuleIdx !== -1 ||
							labelRules.length > 0 ||
							namespaceLabelRules.length > 0
								? 'and'
								: undefined
						}
						value={value[clusterRuleIdx]}
						onChange={function onSimpleRuleChange(mutatorFn) {
							onChange((draft) => {
								const partialState = draft[clusterRuleIdx];
								const possibleResult = mutatorFn(partialState);
								if (possibleResult !== undefined) draft[clusterRuleIdx] = possibleResult;
							});
						}}
						onDelete={function onSimpleRuleDelete() {
							onChange((draft) => draft.filter((_, i) => i !== clusterRuleIdx));
						}}
					/>
				)}

				{namespaceRuleIdx === -1 && (
					<Typo variant="D/Medium/Body-S" color="secondary" className={styles.addButton}>
						<RouterLink
							inherit
							onClick={addNamespaceRule}
							to="#"
							data-test="policy-complex-rule-add-namespace"
							className={styles.addButtonText}
						>
							<Icon name="Add/Regular" size={20} />
							Add namespace name
						</RouterLink>
					</Typo>
				)}

				{assetRuleIdx === -1 && (
					<Typo variant="D/Medium/Body-S" color="secondary" className={styles.addButton}>
						<RouterLink
							inherit
							onClick={addAssetRule}
							to="#"
							data-test="policy-complex-rule-add-asset"
							className={styles.addButtonText}
						>
							<Icon name="Add/Regular" size={20} />
							Add service name
						</RouterLink>
					</Typo>
				)}

				<Typo variant="D/Medium/Body-S" color="secondary" className={styles.addButton}>
					<RouterLink
						inherit
						onClick={addLabelRule}
						to="#"
						data-test="policy-complex-rule-add-label"
						className={styles.addButtonText}
					>
						<Icon name="Add/Regular" size={20} />
						Add label
					</RouterLink>
				</Typo>

				<Typo variant="D/Medium/Body-S" color="secondary" className={styles.addButton}>
					<RouterLink
						inherit
						onClick={addNamespaceLabelRule}
						to="#"
						data-test="policy-complex-rule-add-namespace-label"
						className={styles.addButtonText}
					>
						<Icon name="Add/Regular" size={20} />
						Add namespace label
					</RouterLink>
				</Typo>

				{!hideAssetGroups && assetGroupRuleIdx === -1 && (
					<Typo variant="D/Medium/Body-S" color="secondary" className={styles.addButton}>
						<RouterLink
							inherit
							onClick={addAssetGroupRule}
							to="#"
							data-test="policy-complex-rule-add-group"
							className={styles.addButtonText}
						>
							<Icon name="Add/Regular" size={20} />
							Add group
						</RouterLink>
					</Typo>
				)}

				{clusterRuleIdx === -1 && (
					<Typo variant="D/Medium/Body-S" color="secondary" className={styles.addButton}>
						<RouterLink
							inherit
							onClick={addClusterRule}
							to="#"
							data-test="policy-complex-rule-add-cluster"
							className={styles.addButtonText}
						>
							<Icon name="Add/Regular" size={20} />
							Add cluster
						</RouterLink>
					</Typo>
				)}
			</div>
		</div>
	);
}

export default ComplexRule;
