import Icon from 'components/Icon';
import Typo from 'components/typography/Typo';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { PATHS } from 'services/router';
import styles from './index.module.css';

function DataTypeFormHeader(props: { isNew: boolean; name: string }) {
	const { isNew, name } = props;

	return (
		<Header>
			<Header.Breadcrumbs
				steps={[{ path: PATHS.DATA_TYPES_LIST, name: 'Data types' }]}
				finalStep={isNew ? 'New custom data type' : name}
			/>

			<Header.Title>
				<Typo variant="D/Medium/H400-Page-Title" className={styles.header}>
					<Icon name="Tag/Filled" />
					{isNew ? 'New custom data type' : name}
				</Typo>
			</Header.Title>
		</Header>
	);
}

export { DataTypeFormHeader };
