import Chip from 'components/Chip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Typo from 'components/typography/Typo';
import { DatabaseRoleItem } from 'models/databases/dto.ts';
import styles from '../index.module.css';

type Props = {
	databaseRole: DatabaseRoleItem;
	onClick: () => void;
};

export const AccessControlTableItem = ({ databaseRole, onClick }: Props) => {
	const { name, aggregated_permissions, type } = databaseRole;

	return (
		<GridRow
			data-test="group-table-row"
			className={styles.rowContainer}
			hover
			border
			onClick={onClick}
		>
			<GridCell className={styles.gapCell} verticalAlign="middle">
				<Typo variant="D/Regular/Body-S">{name}</Typo>
			</GridCell>

			<GridCell verticalAlign="middle">
				<Typo variant="D/Regular/Body-S">{type}</Typo>
			</GridCell>

			<GridCell verticalAlign="middle" className={styles.aggregatedPermissions}>
				{aggregated_permissions.slice(0, 4).map((perm) => (
					<Chip
						key={perm}
						className={styles.responseCodeChip}
						label={perm}
						size="small"
						color="primary"
						theme="assetNeutral"
					/>
				))}
				{aggregated_permissions.length > 4 && (
					<Chip
						className={styles.responseCodeChip}
						label={`+${aggregated_permissions.length - 4}`}
						size="small"
						color="primary"
						theme="assetNeutral"
					/>
				)}
			</GridCell>
		</GridRow>
	);
};
