import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import { Option } from 'components/form/Select';
import Icon from 'components/Icon';
import Typo from 'components/typography/Typo';
import { getNamespacesFx } from 'models/namespaces/effects';
import { namespaceLabelsList } from 'models/namespaces/store';
import { PolicyRuleItem } from 'models/policies/dto';
import { RuleBuilderSelect, RuleBuilderComplexSelect } from '../RuleBuilderSelect';
import styles from './index.module.pcss';

type RuleValueProps = {
	value: string;
	onDelete: (value: string) => void;
};

function RuleValue(props: RuleValueProps) {
	const { value, onDelete } = props;

	return (
		<span className={styles.ruleValue} onClick={() => onDelete(value)}>
			{value}
			<Icon name="DismissCircle/Filled" size={16} className={styles.deleteName} />
		</span>
	);
}

const anyOption: Option = { id: -1, name: 'any value' };
const noneOption: Option = { id: -2, name: 'none' };

type Props = {
	prefix?: string;
	value: PolicyRuleItem;
	onChange: (mutatorFn: (draft: PolicyRuleItem) => void) => void;
	onDelete: () => void;
};

// It is a bit like SimpleRule. Differences:
//	1) it is key:value rule
//	2) with operator
//
// This component is largerly a copied from LabelRule. Both will be deleted soon due to Policy redesign,
// so we can tolerate a bit of copypaste.
function NamespaceLabelRule(props: Props) {
	const { prefix, value, onChange, onDelete } = props;

	const namespaceLabels = useStore(namespaceLabelsList);

	useEffect(() => {
		getNamespacesFx();
	}, []);

	const labelKeyOptions = useMemo(() => {
		return namespaceLabels.map((label) => label.key);
	}, [namespaceLabels]);

	const labelValueOptions = useMemo(() => {
		const foundLabel = namespaceLabels.find((l) => l.key === value.key);

		const labelValues = foundLabel?.values || [];

		const regularOptions: Option[] = labelValues.map((val) => ({ id: val, name: val }));

		return [anyOption, noneOption].concat(regularOptions);
	}, [namespaceLabels, value]);

	// single select
	const onSelectLabelKey = useCallback(
		(labelKey: string) => {
			onChange((draft) => {
				draft.key = labelKey;
				draft.operator = 'is_set';
				draft.values = [];
			});
		},
		[onChange]
	);

	const onDeleteValue = useCallback(
		function onDeleteValue(val: string) {
			onChange((draft) => {
				draft.values = draft.values.filter((v) => v !== val);
				draft.operator = draft.values.length === 0 ? 'is_set' : 'is';
			});
		},
		[onChange]
	);

	const ruleValues = useMemo(() => {
		const result = value.values.flatMap((v, i) => {
			return [
				<RuleValue key={i} value={v} onDelete={onDeleteValue} />,
				<span key={-1 - i} className={styles.rowItem}>
					{' , '}
				</span>,
			];
		});

		result.pop();

		return result;
	}, [value, onDeleteValue]);

	return (
		<div className={styles.container}>
			{prefix && (
				<Typo component="span" variant="D/Medium/Body-S" className={styles.prefix}>
					{prefix}
				</Typo>
			)}

			<RuleBuilderSelect
				options={labelKeyOptions}
				value={value.key}
				onChange={onSelectLabelKey}
				label={{
					primary: value.key ? 'Namespace label: ' : 'Namespace label: Choose label...',
					secondary: value.key,
				}}
			/>

			{!!value.key && (
				<>
					<span className={styles.joiningWord}>with</span>

					<RuleBuilderComplexSelect
						value={value}
						options={labelValueOptions}
						onChange={onChange}
						label={
							value.operator === 'is'
								? 'value:'
								: value.operator === 'is_set'
								? 'any value'
								: 'none'
						}
					/>

					{ruleValues}
				</>
			)}

			<ButtonIcon icon="Delete/Filled" onClick={onDelete} className={styles.deleteRule} />
		</div>
	);
}

export default NamespaceLabelRule;
