import { IHeadCell } from 'components/table/EnhancedTableHead';
import { DatabaseRoleItem } from 'models/databases/dto';

const tableConfig: IHeadCell<DatabaseRoleItem>[] = [
	{
		id: 'name',
		numeric: false,
		label: 'Role name',
		sortable: false,
		filter: false,
	},
	{
		id: 'type',
		numeric: false,
		label: 'Type',
		sortable: false,
		filter: false,
	},
	{
		id: 'aggregated_permissions',
		numeric: false,
		label: 'Privileges',
		sortable: false,
		filter: false,
	},
];

export default tableConfig;
