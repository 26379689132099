import cn from 'classnames';
import { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import Preloader from 'components/Preloader';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridHead from 'components/table/GridHead';
import GridRow from 'components/table/GridRow';
import GridTable from 'components/table/GridTable';
import Typo from 'components/typography/Typo';
import { DatabaseDetails } from 'models/databases/dto';
import { getNoSQLDatabaseCollectionList } from 'models/noSQLDatabases/api';
import { NoSQLDatabaseCollectionItem } from 'models/noSQLDatabases/dto';
import { PATHS } from 'services/router';
import { unselectInteractsWith } from '../../model/store';
import commonStyles from '../index.module.css';
import styles from './index.module.pcss';

type Props = {
	databaseId: DatabaseDetails['id'];
};

export function Collections({ databaseId }: Readonly<Props>) {
	const [collectionList, setCollectionList] = useState<NoSQLDatabaseCollectionItem[]>([]);
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	useEffect(() => {
		setLoading(true);

		getNoSQLDatabaseCollectionList(databaseId, {
			limit: 100,
			offset: 0,
		})
			.then(({ collections }) => setCollectionList(collections))
			.catch((error) => {
				console.error('Failed to fetch NoSQL database collections list:', error);
			})
			.finally(() => {
				setLoading(false);
			});

		return unselectInteractsWith;
	}, [databaseId]);

	return (
		<div className={commonStyles.detailsContainer}>
			<Preloader isLoading={loading}>
				<div className={cn(commonStyles.sidePaddings, commonStyles.scrollContainer)}>
					<GridTable className={commonStyles.table}>
						<GridHead>
							<GridRow className={cn(styles.dataflowRowContainer, styles.gridHeaderRow)} border>
								<GridCell head className={styles.gridCellHead}>
									name
								</GridCell>

								<GridCell head className={styles.gridCellHead}>
									data types
								</GridCell>
							</GridRow>
						</GridHead>

						<GridBody className={styles.tableBody}>
							{collectionList.map(({ name, data_types, id }) => {
								return (
									<GridRow
										key={id}
										className={styles.dataflowRowContainer}
										onClick={() => {
											const path = generatePath(PATHS.NOSQL_DATABASES_COLLECTION_ITEM, {
												id,
											});
											history.push(path);
										}}
										border
										hover
									>
										<GridCell className={styles.gapCell}>
											<Typo variant="D/Medium/Meta">{name}</Typo>
										</GridCell>

										<GridCell>
											<PiiTypeList noHighlight data={data_types} size="extraSmall" />
										</GridCell>
									</GridRow>
								);
							})}
						</GridBody>

						<Button
							color="tertiary"
							size="small"
							endIcon={<Icon name="Open/Regular" size={20} />}
							fullWidth
							href={generatePath(PATHS.NOSQL_DATABASES_ITEM, { id: databaseId })}
							target="_blank"
							className={commonStyles.detailedInfo}
						>
							View NoSQL database page
						</Button>
					</GridTable>
				</div>
			</Preloader>
		</div>
	);
}
