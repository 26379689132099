import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import Preloader from 'components/Preloader';
import { getAssetsExtended } from 'models/assetsExtended/api';
import { TAssetsItem } from 'models/assetsExtended/dto';
import { getEndpoints } from 'models/endpointsV2/api';
import { EndpointsItem } from 'models/endpointsV2/dto';
import { activityStore } from 'models/recentActivity/store';
import { PATHS } from 'services/router';
import { WidgetHeader } from '../WidgetParts';
import AssetRow from './AssetRow';
import EndpointRow from './EndpointRow';
import styles from './index.module.pcss';
import TopAsset from './TopAsset';

function TopAssets() {
	const [isLoading, setLoading] = useState(true);
	const [endpointsData, setEndpoints] = useState<EndpointsItem[]>([]);
	const [assetsData, setAssets] = useState<TAssetsItem[]>([]);
	const hasTraffic = useStore(activityStore);

	function filterBySensitivity<T extends { sensitivity: 'High' | 'Medium' | 'Low' | 'N/A' }>(
		data: T[]
	) {
		return data.filter((item: T) => item.sensitivity !== 'N/A');
	}

	useEffect(() => {
		(async () => {
			if (hasTraffic) {
				Promise.all([
					getEndpoints({ limit: 5, offset: 0 }),
					getAssetsExtended({ limit: 5, offset: 0 }),
				]).then(([/* { hosts }, */ { endpoints }, { assets }]) => {
					setEndpoints(filterBySensitivity<EndpointsItem>(endpoints));
					setAssets(filterBySensitivity<TAssetsItem>(assets));
				});
			}

			setLoading(false);
		})();
	}, [hasTraffic]);

	return (
		<Preloader isLoading={isLoading} className={styles.container}>
			<WidgetHeader header="Top 5 sensitive services" />

			<div className={styles.assetsContainer}>
				<TopAsset
					title="Endpoints"
					dataLength={endpointsData.length}
					seeAllLink={PATHS.API_ENDPOINTS}
					dataTest="endpoints-asset-table"
				>
					{endpointsData.map((item) => (
						<EndpointRow key={item.id} {...item} />
					))}
				</TopAsset>

				<TopAsset
					title="Services"
					dataLength={assetsData.length}
					seeAllLink={PATHS.SERVICES}
					dataTest="assets-asset-table"
				>
					{assetsData.map((item) => (
						<AssetRow key={item.id} {...item} />
					))}
				</TopAsset>
			</div>
		</Preloader>
	);
}

export default TopAssets;
