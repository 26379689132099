import { useStore } from 'effector-react';
import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router';
import Badge from 'components/Badge';
import { piiFilterStore } from 'components/PiiGlobalFilterV2/model';
import { TabLink, Tabs, useTabLink } from 'components/Tabs';
import { BreadcrumbProps, dataStoragesSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { CountersJson } from 'models/countersV2/dto';
import { getCountersFx } from 'models/countersV2/effects';
import { $counters } from 'models/countersV2/store';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { UniversalSearchInLayout } from 'views/common/UniversalSearch';
import styles from './index.module.css';

type RouteItem = {
	path: string;
	label: string;
	routeCounter: keyof CountersJson;
	dataTest: string;
};

const routeList: RouteItem[] = [
	{
		path: PATHS.S3_BUCKETS,
		label: 'S3 buckets',
		routeCounter: 'buckets',
		dataTest: 'menu-counter-s3-buckets',
	},
	{
		path: PATHS.KAFKA_INSTANCES,
		label: 'Kafka clusters',
		routeCounter: 'kafka_instances',
		dataTest: 'menu-counter-kafka-instances',
	},
	{
		path: PATHS.KAFKA_TOPICS,
		label: 'Kafka topics',
		routeCounter: 'kafka_topics',
		dataTest: 'menu-counter-kafka-topics',
	},
	{
		path: PATHS.DATABASES,
		label: 'Databases',
		routeCounter: 'sql_db_databases',
		dataTest: 'menu-counter-databases',
	},
	{
		path: PATHS.NOSQL_DATABASES,
		label: 'NoSQL databases',
		routeCounter: 'no_sql_db_databases',
		dataTest: 'menu-counter-nosql-databases',
	},
];

const tabs = routeList.map((routeItem) => routeItem.path);

type Props = {
	children: ReactNode;
};
function DataStorage(props: Props) {
	const { children } = props;

	const location = useLocation();
	const currentTab = useTabLink(tabs);

	const counters = useStore($counters);
	const { nonEmpty } = useStore(piiFilterStore);

	useEffect(() => {
		getCountersFx(nonEmpty);
	}, [location.pathname, nonEmpty]);

	return (
		<>
			<Tabs value={currentTab}>
				{routeList.map((route) => (
					<TabLink
						label={
							<>
								{route.label}{' '}
								<Badge
									badgeContent={toLocaleString(counters[route.routeCounter])}
									data-test={route.dataTest}
								/>
							</>
						}
						value={route.path}
						key={route.path}
					/>
				))}

				<UniversalSearchInLayout />
			</Tabs>

			<div className={styles.content}>{children}</div>
		</>
	);
}

type DataStorageHeaderProps = {
	breadcrumbsFinalStep: BreadcrumbProps['finalStep'];
};

DataStorage.Header = function DataStorageHeader(props: DataStorageHeaderProps) {
	const finalStep = props.breadcrumbsFinalStep;

	return (
		<Header>
			<Header.Breadcrumbs steps={dataStoragesSteps} finalStep={finalStep} />

			<Header.Title>Data stores</Header.Title>

			<Header.SensitiveSwitcher />
		</Header>
	);
};

export default DataStorage;
