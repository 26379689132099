import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { NoSQLDatabaseSchemaDetails } from 'models/noSQLDatabases/dto';
import { fileTypeLabels } from 'models/s3BucketCluster/dto';
import { lastSeen } from 'services/lastSeen';
import styles from './index.module.css';

interface Props {
	schema: NoSQLDatabaseSchemaDetails;
}

function Summary({ schema }: Readonly<Props>) {
	const { type, last_checked, sensitivity, data_types } = schema;
	const lastCheckedAt = lastSeen({ timestamp: last_checked });
	const typeLabel = fileTypeLabels[type] || fileTypeLabels['unknown'];

	return (
		<div>
			<Typo variant="D/Medium/H100-Header">Summary</Typo>

			<div className={styles.columns}>
				<div className={styles.leftColumn}>
					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Content type
					</Typo>

					<Typo variant="D/Regular/Body-S" data-test="message-schema-summary-type">
						{typeLabel}
					</Typo>

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						last seen
					</Typo>

					<Typo variant="D/Regular/Body-S" data-test="message-schema-summary-last-checked">
						<Tooltip className={styles.lastChecked} title={lastCheckedAt.date}>
							{lastCheckedAt.diff}
						</Tooltip>
					</Typo>
				</div>

				<div className={styles.rightColumn}>
					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Sensitivity
					</Typo>

					<SensitivityChip
						sensitivity={sensitivity}
						data-test="message-schema-summary-sensitivity"
					/>

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Data types
					</Typo>

					<PiiTypeList data={data_types} noHighlight dataTest="message-schema-summary-data-types" />
				</div>
			</div>
		</div>
	);
}

export { Summary };
