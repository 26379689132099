import cn from 'classnames';
import { useStore } from 'effector-react';
import { Fragment, useEffect, useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { AISession } from 'models/aiCopilot/dto';
import { getAISessionsListFx } from 'models/aiCopilot/effects';
import { aiSessionsList } from 'models/aiCopilot/store';
import { DateFormat, dayjs } from 'services/dayjs';
import { PATHS } from 'services/router';
import styles from './index.module.pcss';

export function History() {
	const history = useHistory();
	const sessionsList = useStore(aiSessionsList);
	const { sessionId }: { sessionId: string } = useParams();

	useEffect(() => {
		getAISessionsListFx();
	}, []);

	function goToSession(id?: number) {
		const path = generatePath(PATHS.AI_COPILOT, {
			sessionId: id,
		});

		history.push(path);
	}

	const sessionsSortByDate = useMemo(() => {
		const result: { [key: string]: AISession[] } = {};

		sessionsList
			.sort((s1, s2) => s2.created_at - s1.created_at)
			.forEach((session) => {
				const date = dayjs(session.created_at).format(DateFormat.cutDate);

				if (!result[date]) {
					result[date] = [session];
				} else {
					result[date].push(session);
				}
			});

		return result;
	}, [sessionsList]);

	if (Object.keys(sessionsSortByDate).length === 0) {
		return (
			<div className={cn(styles.sessionsContainer, styles.empty)}>
				<Typo
					variant="D/Regular/Body-S"
					color="secondary"
					className={styles.emptyHistoryDescription}
				>
					<Icon name="ChatOff/Regular" size={20} />
					Your chat history is currently empty.
				</Typo>
			</div>
		);
	}

	return (
		<div className={styles.sessionsContainer}>
			<div className={styles.newChatButtonContainer}>
				<Button
					onClick={() => goToSession()}
					size="extraSmall"
					fullWidth
					startIcon={<Icon name="Add/Regular" size={20} />}
					color="secondary"
				>
					New Chat
				</Button>
			</div>

			<div className={styles.sessionsList}>
				{Object.keys(sessionsSortByDate).map((date) => (
					<Fragment key={date}>
						<Typo className={styles.date} variant="D/Regular/Meta-S-CAP" color="secondary">
							{date}
						</Typo>

						{sessionsSortByDate[date].map((session) => (
							<Tooltip title={session.name} placement="left" key={session.id}>
								<Typo
									onClick={() => goToSession(session.id)}
									variant="D/Medium/Body-S"
									className={cn(styles.session, { [styles.active]: session.id === +sessionId })}
								>
									{session.name}
								</Typo>
							</Tooltip>
						))}
					</Fragment>
				))}
			</div>
		</div>
	);
}
