import { AssetGroup, AssetGroupCounters, AssetGroupList, AssetGroupListItem } from './dto';

const assetGroupExample: AssetGroup = {
	created_at: 0,
	updated_at: 0,
	id: 1,
	name: 'Service group name',
	description: 'Service group description',
	owner: 'Owner',
	rules: [
		[
			{
				type: 'namespace',
				operator: 'is',
				key: '',
				values: ['transact-controller', 'dev-fin-agent'],
			},
		],
		[
			{
				type: 'namespace',
				operator: 'is',
				key: '',
				values: ['fin-monitoring'],
			},
			{
				type: 'asset',
				operator: 'is',
				key: '',
				values: [
					'web-backend',
					'auth-service',
					'web-backend2',
					'auth-service2',
					'web-backend3',
					'auth-service3',
					'web-backend4',
					'auth-service4',
					'web-backend5',
					'auth-service5',
					'web-backend26',
					'auth-service26',
					'web-backend37',
					'auth-service37',
					'web-backend48',
					'auth-service48',
				],
			},
		],
	],
};

const groups: AssetGroupListItem[] = [
	{
		id: 20230302,
		assets: 5,
		name: 'Ordering Prod',
		description:
			'A production group of microservices which are responsible for placing orders and managing customer order history. It handles the process of adding products to a shopping cart, calculating prices and taxes, and processing payments.',
		owner: 'Hans Bauer',
		// rules: [
		// 	[
		// 		{
		// 			type: 'asset',
		// 			operator: 'is',
		// 			key: '',
		// 			values: [
		// 				'ord-prod-ship',
		// 				'ord-prod-pay',
		// 				'cart-ord-track',
		// 				'order-prod-web',
		// 				'ord-app-back',
		// 			],
		// 		},
		// 	],
		// ],
		created_at: 1649289600000,
		updated_at: 1649289600000,
	},
	{
		id: 20230303,
		assets: 113,
		name: 'Ordering Stage',
		description:
			"A staging group of microservices for testing and previewing of orders before they are processed in the production environment. It is used to ensure that the ordering process functions correctly and that customer's orders are accurately placed and processed before they are made live.",
		owner: 'Katharina Fischer',
		// rules: [
		// 	[
		// 		{
		// 			type: 'asset',
		// 			operator: 'is',
		// 			key: '',
		// 			values: ['ord-dev-queue', 'ord-dev-bf-team', 'ord-deliv-team'],
		// 		},
		// 	],
		// ],
		created_at: 1654707653934,
		updated_at: 1654707653934,
	},
	{
		id: 20230304,
		assets: 2,
		name: 'User Cart and Analytics',
		description:
			"A group of microservices that handle the management of customer's shopping cart, analysis of customer behaviour and purchasing patterns. It allows for the tracking of customer interactions and the ability to make data-driven decisions to improve the customer shopping experience and increase sales.",
		owner: 'Emily Smith',
		created_at: 1659516915000,
		updated_at: 1659516915000,
	},
	{
		id: 20230305,
		assets: 43,
		name: 'Retail Backend',
		description:
			'A group of microservices that provide the backbone infrastructure for a retail business, it includes functionalities such as inventory management, shipping and tracking, financial management and customer management. It enables the smooth and efficient operation of a retail business by automating and streamlining key processes and providing real-time data and insights.',
		owner: 'Nicholas Garcia',
		created_at: 1672566915000,
		updated_at: 1659516915000,
	},
	{
		id: 20230306,
		assets: 3,
		name: 'Payment Aggregator',
		description:
			'A group of microservices that allow the integration of multiple payment methods and providers into a single platform. It also handles the security and compliance aspects of the payment process, ensuring that all transactions are secure and compliant with industry standards.',
		owner: 'Thomas Weber',
		created_at: 1639216515000,
		updated_at: 1659516915000,
	},
];

const assetGroupListExample: AssetGroupList = {
	groups,
	total: groups.length,
};

const assetGroupCountersExample: AssetGroupCounters = {
	assets: 2,
	dataflows: 11,
};

export { assetGroupListExample, assetGroupExample, assetGroupCountersExample };
