import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { generatePath, useLocation } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import Badge from 'components/Badge';
import { piiFilterStore } from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import { TabLink, TabPanel, Tabs } from 'components/Tabs';
import Typo from 'components/typography/Typo';
import { dataStoragesSteps, kafkaTopicsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getKafkaTopicItem, getKafkaTopicStatus } from 'models/kafkaTopics/api';
import { KafkaTopicStatus, KafkaTopicTableItem } from 'models/kafkaTopics/dto';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { ConsumerGroupsMembersTable } from './ConsumerGroupsMembersTable';
import MessageSchemaList from './MessageSchemas/MessageSchemaList';
import { Summary } from './Summary';

function TopicError() {
	const params: {
		id: string;
	} = useParams();

	return (
		<>
			<Header>
				<Header.Breadcrumbs steps={dataStoragesSteps} finalStep="Not found" />

				<Header.Title>Error has occurred while loading kafka topic info</Header.Title>
			</Header>

			<Typo variant="D/Regular/Body-S" color="secondary">
				Kafka topic with id={params.id} not found or was deleted.
			</Typo>
		</>
	);
}

function TopicItemWrapper() {
	const [topic, setTopic] = useState<KafkaTopicTableItem | null>(null);
	const [isLoading, setLoading] = useState(true);

	const params: { id: string } = useParams();

	useEffect(() => {
		getKafkaTopicItem(Number(params.id))
			.then(setTopic)
			.finally(() => setLoading(false));
	}, [params.id]);

	return (
		<Preloader isLoading={isLoading}>
			{topic ? <TopicItem topic={topic} /> : <TopicError />}
		</Preloader>
	);
}

const tabLabels = {
	summary: 'Summary',
	'message-schemas': 'Message schemas',
	'assigned-members': 'Assigned members',
};

function TopicItem({ topic }: { topic: KafkaTopicTableItem }) {
	const location = useLocation();
	const history = useHistory();
	const params: {
		tab?: 'summary';
	} = useParams();

	const { nonEmpty } = useStore(piiFilterStore);
	const [counters, setCounters] = useState<KafkaTopicStatus>({
		has_unsupported_content: false,
		consumer_groups_members: 0,
		data_samples: 0,
		schemas: 0,
	});

	useEffect(() => {
		getKafkaTopicStatus({ id: topic.id, nonEmpty }).then(setCounters);
	}, [location.pathname, topic.id, nonEmpty]);

	const tabLinks = useMemo(
		() => ({
			summary: generatePath(PATHS.KAFKA_TOPICS_ITEM, {
				id: topic.id,
				tab: 'summary',
			}),
			'message-schemas': generatePath(PATHS.KAFKA_TOPICS_ITEM, {
				id: topic.id,
				tab: 'message-schemas',
			}),
			'assigned-members': generatePath(PATHS.KAFKA_TOPICS_ITEM, {
				id: topic.id,
				tab: 'assigned-members',
			}),
		}),
		[topic.id]
	);

	// Set default tab, if not specified.
	useEffect(function () {
		if (!params.tab) {
			const path = generatePath(PATHS.KAFKA_TOPICS_ITEM, {
				...params,
				tab: 'summary',
			});
			history.replace(path + history.location.search);
		}
	}, []);

	const isScanned = !!topic.last_seen;

	const steps = [
		...kafkaTopicsSteps,
		{
			path: generatePath(PATHS.KAFKA_TOPICS_ITEM, {
				id: topic.id,
				tab: 'summary',
			}),
			name: topic.name,
		},
	];

	return (
		<>
			<Header>
				<Header.Breadcrumbs steps={steps} finalStep={tabLabels[params.tab || 'summary']} />

				<Header.Title>{topic.name}</Header.Title>

				<Header.SensitiveSwitcher />
			</Header>

			<Tabs value={tabLinks[params.tab || 'summary']}>
				<TabLink value={tabLinks['summary']} label={tabLabels['summary']} />

				<TabLink
					value={tabLinks['message-schemas']}
					label={
						<>
							{tabLabels['message-schemas']}
							<Badge
								badgeContent={isScanned ? toLocaleString(counters.schemas) : 'N/A'}
								data-test="kafka-topic-schemas-count"
							/>
						</>
					}
				/>

				<TabLink
					value={tabLinks['assigned-members']}
					label={
						<>
							{tabLabels['assigned-members']}
							<Badge
								badgeContent={isScanned ? toLocaleString(counters.consumer_groups_members) : 'N/A'}
								data-test="kafka-topic-members-count"
							/>
						</>
					}
				/>
			</Tabs>

			{/* TABS CONTENT */}
			<TabPanel value={params.tab} index="summary">
				<Summary topic={topic} />
			</TabPanel>

			<TabPanel value={params.tab} index="message-schemas">
				<MessageSchemaList
					topicId={topic.id}
					hasUnsupportedContent={counters.has_unsupported_content}
				/>
			</TabPanel>

			<TabPanel value={params.tab} index="assigned-members">
				<ConsumerGroupsMembersTable topic={topic} />
			</TabPanel>
		</>
	);
}

export { TopicItemWrapper as TopicItem };
