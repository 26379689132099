import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Preloader from 'components/Preloader';
import { getNoSQLDatabaseSample } from 'models/noSQLDatabases/api';
import { NoSQLDatabaseSampleDetails } from 'models/noSQLDatabases/dto';
import { JSONViewer } from 'views/common/SampleViewer/JSONViewer';
import styles from './index.module.css';
import { SampleHeader } from './SampleHeader';
import { WashedSample, WashedSampleProps } from './WashedSample';

function NoSQLSampleViewers() {
	const history = useHistory();
	const [sample, setSample] = useState<NoSQLDatabaseSampleDetails>();
	const [loading, setLoading] = useState(true);

	const params: {
		id: string;
	} = useParams();

	useEffect(() => {
		getNoSQLDatabaseSample(+params.id).then((response) => {
			setSample(response);
			setLoading(false);
		});
	}, []);

	const Viewer = useMemo(() => {
		if (!sample?.samples) {
			return null;
		}

		if (sample?.type === 'json') {
			return <JSONViewer {...sample} {...sample?.samples[0]} />;
		}

		return null;
	}, [sample]);

	return (
		<Preloader isLoading={loading}>
			{sample ? (
				<>
					<SampleHeader sample={sample} />

					<div className={styles.wrapper}>{Viewer}</div>
				</>
			) : (
				<WashedSample id={params.id} state={history.location.state as WashedSampleProps['state']} />
			)}
		</Preloader>
	);
}

export { NoSQLSampleViewers };
