import { NamespaceLabels } from './dto';

const namespaceLabelsExample: NamespaceLabels = {
	labels: [
		{
			namespace: 'smat',
			cluster_id: 5,
			key: 'PCI',
			value: 'allowed',
		},
		{
			namespace: 'smat',
			cluster_id: 5,
			key: 'depoyment',
			value: 'prod',
		},
	],
	total: 2,
};

export { namespaceLabelsExample };
