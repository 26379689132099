import { useStore } from 'effector-react';
import { generatePath } from 'react-router';
import ExternalLink from 'components/typography/ExternalLink';
import { s3BucketsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { S3BucketItem } from 'models/s3Buckets/dto';
import { s3RegionsByKeyword } from 'models/s3Regions/store';
import { PATHS } from 'services/router';
import styles from './index.module.pcss';

const tabLabels = {
	summary: 'Summary',
	files: 'Files',
	connections: 'Connections',
};

type Props = {
	tab: 'summary' | 'files' | 'connections';
	bucket: S3BucketItem;
};

function S3BucketHeader(props: Props) {
	const { tab, bucket } = props;

	const s3Regions = useStore(s3RegionsByKeyword);

	const regionDescription = s3Regions[bucket.region]?.description || '';

	const steps = [
		...s3BucketsSteps,
		{
			path: generatePath(PATHS.S3_BUCKETS_ITEM, {
				id: bucket.id,
				tab: 'summary',
			}),
			name: bucket.name,
		},
	];

	return (
		<Header>
			<Header.Breadcrumbs steps={steps} finalStep={tabLabels[tab]} />

			<Header.Title>{bucket.name}</Header.Title>
			<Header.Subtitle>{regionDescription}</Header.Subtitle>

			{bucket.cloud_console_url && (
				<Header.CustomAction>
					<ExternalLink href={bucket.cloud_console_url} className={styles.link}>
						View in cloud console
					</ExternalLink>
				</Header.CustomAction>
			)}

			<Header.SensitiveSwitcher />
		</Header>
	);
}

export { S3BucketHeader };
