import { createEffect } from 'effector';
import { getNoSQLDatabaseInstanceList } from './api';
import { NoSQLDatabaseInstanceList } from './dto';

/* API */

export const getNoSQLDatabaseInstancesFx = createEffect<void, NoSQLDatabaseInstanceList>();

/* IMPLEMENTATION */
getNoSQLDatabaseInstancesFx.use(getNoSQLDatabaseInstanceList);
