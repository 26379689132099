import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { useLocation } from 'react-router-dom';
import Alert, { AlertSeverity } from 'components/Alert';
import { DataTypeFilter } from 'components/FilterLine/DataTypeFilter';
import { enqueueSnackbar } from 'components/Snackbar';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { dataTypesPartsByIds } from 'models/dataTypes/helpers';
import { generatePoliciesFx } from 'models/policies/effects';
import { policyListExample } from 'models/policies/examples';
import { policiesLoaded, policyStore } from 'models/policies/store';
import { APIError } from 'services/api/httpRequest';
import { PATHS } from 'services/router';
import CongratulationModal from './CongratulationModal';
import styles from './index.module.css';
import NoPoliciesBlock from './NoPoliciesBlock';
import PolicyTable from './PolicyTable';

const newPolicyPath = generatePath(PATHS.POLICY_ITEM, { id: 'new' });

function PolicyList() {
	const [generationLoading, setGenerationLoading] = useState(false);
	let policies = useStore(policyStore);
	let skeletonMode = false;
	const loaded = useStore(policiesLoaded);
	const isPolicies = policies.policies.length > 0;

	const [dataTypes, setDataTypes] = useState<number[]>([]);

	if (loaded && !isPolicies) {
		skeletonMode = true;
		policies = policyListExample;
	}

	const location = useLocation();

	useEffect(() => {
		const dataTypesQuery = new URLSearchParams(location.search).get('data-types')?.split(',') || [];
		setDataTypes(dataTypesQuery.map(Number).filter((value) => !Number.isNaN(value)));
	}, []);

	const [alertOpen, setAlertOpen] = useState(false); // To enable, do useState(true)

	const dataTypeParts = useMemo(() => dataTypesPartsByIds(dataTypes), [dataTypes]);

	const sortedAndFiltered = useMemo(() => {
		const sorted = [...policies.policies].sort(function (a, b) {
			return a.created_at - b.created_at;
		});

		const dataTypesFilter = [...dataTypeParts.groups, ...dataTypeParts.outOfGroup].map(
			({ id }) => id
		);

		// if (piiFilter.dataTypes.length === 0) return sorted;

		return sorted.filter(
			(policy) =>
				dataTypesFilter.length === 0 ||
				dataTypesFilter.some((dtSelected) => policy.data_types.includes(dtSelected))
		);
	}, [policies, dataTypeParts]);

	const generatePolicies = async () => {
		setGenerationLoading(true);

		try {
			await generatePoliciesFx();

			await CongratulationModal();
		} catch (error) {
			if (error instanceof APIError) {
				const { message } = await error.response.json();

				enqueueSnackbar(message);
			} else {
				throw error;
			}
		}

		setGenerationLoading(false);
	};

	return (
		<div>
			<Header>
				<Header.Breadcrumbs finalStep="Policies" />

				<Header.Title>Policies</Header.Title>

				<Header.MainButton to={newPolicyPath} data-test="policy-header-create-button">
					New policy
				</Header.MainButton>
			</Header>

			{alertOpen && (
				<Alert
					severity={AlertSeverity.info}
					onClick={() => setAlertOpen(false)}
					header="👀 Our model is still exploring your infrastructure."
					className={styles.alert}
				/>
			)}

			<div className={styles.dropdownContainer}>
				<DataTypeFilter fixed showUnused value={dataTypes} onChange={setDataTypes} />
			</div>

			<PolicyTable
				policies={sortedAndFiltered}
				skeletonMode={skeletonMode}
				dataTypesFilter={[...dataTypeParts.groups, ...dataTypeParts.outOfGroup].map(({ id }) => id)}
			/>

			{skeletonMode && <NoPoliciesBlock loading={generationLoading} onClick={generatePolicies} />}
		</div>
	);
}

export { PolicyList };
