import { useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Tooltip from 'components/Tooltip';
import { NoSQLDatabaseSchemaItem } from 'models/noSQLDatabases/dto';
import { fileTypeLabels } from 'models/s3BucketCluster/dto';
import { lastSeen } from 'services/lastSeen';
import { PATHS } from 'services/router';
import styles from './index.module.css';

type Props = {
	schema: NoSQLDatabaseSchemaItem;
};

function TableRow({ schema }: Readonly<Props>) {
	const { id, name, sensitivity, data_types, last_checked, type } = schema;
	const lastSeenValue = useMemo(
		() => lastSeen({ timestamp: last_checked, short: true }),
		[last_checked]
	);

	const history = useHistory();

	function clickHandler() {
		history.push(generatePath(PATHS.NOSQL_DATABASES_SCHEMA_ITEM, { id }));
	}

	const typeLabel = fileTypeLabels[type] || fileTypeLabels['unknown'];

	return (
		<GridRow
			className={styles.rowContainer}
			border
			hover
			onClick={clickHandler}
			data-test="database-schema-row"
		>
			<GridCell dataTest="schema-item-name">{name}</GridCell>

			<GridCell dataTest="schema-table-item-type">{typeLabel}</GridCell>

			<GridCell dataTest="schema-item-timestamp">
				<SensitivityChip sensitivity={sensitivity} />
			</GridCell>

			<GridCell dataTest="schema-item-data_types">
				<PiiTypeList data={data_types} />
			</GridCell>

			<GridCell dataTest="schema-item-last-checked">
				<Tooltip title={lastSeenValue.date} data-test="schema-table-item-last-checked-value">
					{lastSeenValue.diff}
				</Tooltip>
			</GridCell>
		</GridRow>
	);
}

export { TableRow };
