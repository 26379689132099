import cn from 'classnames';
import { useStore } from 'effector-react';
import { produce } from 'immer';
import { MouseEvent, ReactNode, useContext, useMemo } from 'react';
import { AssetJson } from 'models/assets/dto';
import { assetGroupsMap } from 'models/assetsGroups/store';
import { TSensitivity } from 'models/common/dto';
import { TLevel } from 'models/dataMapV2/dto';
import { gatewaysNamesById } from 'models/gateways/store';
import { MapAsset, RagsAndCubesTree } from '../../index';
import { closest } from '../../utils/addParentPropertyToTree';
import { isPseudoRag } from '../../utils/calculateAbsoluteDimensions';
import { TILE_SIZE } from '../index';
import { changeMapControls, mapControlsStore } from '../model/store';
import { ZoomContext } from '../ZoomWrapper';
import aggregateRags from './aggregateRags';
import styles from './index.module.css';
import Platform from './Platform';
import UniversalRag from './UniversalRag';
import { clusteringLevelNumber, levelNumber } from './utils/clusteringLevel';

function getIsoPoint(x: number, y: number) {
	return [x * 0.707107 + y * -0.707107, x * 0.40558 + y * 0.40558];
}

function findParent(rag: RagsAndCubesTree, level: TLevel) {
	const result = closest(rag, level);

	// TODO: isPseudoRag() check is really unnecessary. Rag can't have pseudoRag as a parent, since pseudoRags are
	// on the leaf level of the tree. This knowledge can be baked into type system, but it's not an easy task.
	if (result === null || isPseudoRag(result))
		throw new Error('Unexpected: rag parent not found / is pseudorag');

	return result;
}

// TODO import from somewhere
const sensitivityWeights: Record<TSensitivity, number> = {
	High: 3,
	Medium: 2,
	Low: 1,
	'N/A': 0,
};

type Props = {
	mapAssets: MapAsset[];
	mapAssetsMap: Map<AssetJson['id'], MapAsset>;
	ragsAndCubes: RagsAndCubesTree;
	// TODO: can we get rid of it?
	groupBy: string;
};

function RagsLayer(props: Props) {
	const { mapAssets, mapAssetsMap, ragsAndCubes } = props;
	const { scale, scaleStep, translate } = useContext(ZoomContext);

	const gateways = useStore(gatewaysNamesById);
	const groups = useStore(assetGroupsMap);
	const mapControls = useStore(mapControlsStore);
	const { search, filter, selected, showAssetSensitivity } = mapControls;

	const clusteringLevel = useMemo(() => clusteringLevelNumber(scale), [scale]);

	const filteredAssets = useMemo(
		function () {
			let result = mapAssets;

			if (filter.dataTypes.length) {
				result = result.filter((asset) =>
					filter.dataTypes.some((dataType) => asset.dataTypes.includes(dataType))
				);
			}
			if (filter.namespaces.length) {
				result = result.filter((asset) => filter.namespaces.includes(asset.namespace));
			}
			if (filter.labelKeys.length) {
				result = result.filter((asset) =>
					filter.labelKeys.some((labelKey) => asset.labels.some((label) => label.key === labelKey))
				);
			}
			if (filter.labelValues.length) {
				result = result.filter((asset) =>
					filter.labelValues.some((labelValue) =>
						asset.labels.some((label) => label.value === labelValue)
					)
				);
			}
			if (filter.groups.length) {
				result = result.filter((asset) =>
					filter.groups.some((groupId) => asset.groups.includes(groupId))
				);
			}
			if (filter.clusters.length) {
				result = result.filter((asset) => filter.clusters.includes(asset.cluster_id));
			}
			if (filter.regions.length) {
				result = result.filter((asset) => filter.regions.includes(asset.region));
			}
			if (filter.resourceTypes.length) {
				result = result.filter((asset) => {
					const filterEntityType = determineType(asset.type);

					return filter.resourceTypes.some(({ id }) => id === filterEntityType);
				});
			}

			return new Set(result.map((asset) => asset.elementId));
		},
		[mapAssets, filter]
	);

	function determineType(type: MapAsset['type']) {
		switch (type) {
			case 'nosql_db_database':
			case 'sql_db_database':
			case 'kafka_instance':
			case 's3_bucket':
				return type;
			default:
				return 'service';
		}
	}

	const ragSensitivity = useMemo(
		() =>
			aggregateRags(
				ragsAndCubes,
				(cube: { sensitivity: TSensitivity }) => cube.sensitivity,
				(a: TSensitivity, b: TSensitivity) =>
					sensitivityWeights[a] > sensitivityWeights[b] ? a : b
			),
		[ragsAndCubes]
	);

	const ragHasSelectedCube = useMemo(
		() =>
			aggregateRags(ragsAndCubes, (cube: { elementId: number }) => {
				if (!selected || selected.type === 'namespace' || selected.type === 'group') return false;
				return cube.elementId === selected.id;
			}),
		[ragsAndCubes, selected]
	);

	const ragHasSearchedCube = useMemo(
		() =>
			aggregateRags(ragsAndCubes, (cube: { elementId: number }) => {
				if (!search.searchString) return false;

				const asset = mapAssetsMap.get(cube.elementId);
				const name = asset?.name || '';
				return name.toLocaleLowerCase().includes(search.searchString.toLocaleLowerCase());
			}),
		[ragsAndCubes, search.searchString]
	);

	const ragHasFilteredCube = useMemo(() => {
		return aggregateRags(ragsAndCubes, (cube: { elementId: number }) =>
			filteredAssets.has(cube.elementId)
		);
	}, [ragsAndCubes, filteredAssets]);

	function getRagName(rag: RagsAndCubesTree) {
		if (isPseudoRag(rag)) return '';

		const ragName =
			(rag.level === 'customGroup' && groups[rag.name]?.name) ||
			gateways[Number(rag.name)] ||
			rag.name;

		return rag.level === 'region' ? '' : ragName;
	}

	function getClusterName(rag: RagsAndCubesTree) {
		if (isPseudoRag(rag)) return '';

		const ragName =
			props.groupBy === 'cluster' && rag.name === '0'
				? 'Data stores'
				: (rag.level === 'cluster' && gateways[Number(rag.name)]) || rag.name;
		return ragName;
	}

	const ragsList = useMemo(() => {
		const result: ReactNode[] = [];

		function onRagSelect(selectedRag: RagsAndCubesTree) {
			const newControls = produce(mapControls, (draft) => {
				switch (selectedRag.level) {
					case 'namespace': {
						const namespace = selected?.type === 'namespace' ? selected.name : null;
						const clusterId = selected?.type === 'namespace' ? selected.clusterId : null;
						const parentCluster = findParent(selectedRag, 'cluster');
						const parentClusterId = Number(parentCluster.name);

						draft.selected =
							selectedRag.name === namespace && parentClusterId === clusterId
								? null
								: { type: 'namespace', name: selectedRag.name, clusterId: parentClusterId };
						break;
					}

					case 'customGroup': {
						const groupId = selected?.type === 'group' ? selected.id : null;

						draft.selected =
							Number(selectedRag.name) === groupId
								? null
								: { type: 'group', id: Number(selectedRag.name) };
						break;
					}

					default:
						draft.selected = null;
				}
			});

			changeMapControls(newControls);

			// TODO SMAT-2877 TODO SMAT-2877 FIXME TODO
			setTimeout(() => changeMapControls(newControls), 500);
		}

		// eslint-disable-next-line complexity
		function traverse(rag: RagsAndCubesTree) {
			const sensitivity: TSensitivity = ragSensitivity.get(rag.id) || 'N/A';
			const childSelected = ragHasSelectedCube.get(rag.id) || false;
			const childSearched = ragHasSearchedCube.get(rag.id) || false;
			const childFiltered = ragHasFilteredCube.get(rag.id) || false;
			const position = {
				top: rag.dimensions.absoluteY * TILE_SIZE,
				left: rag.dimensions.absoluteX * TILE_SIZE,
				width: rag.dimensions.width * TILE_SIZE,
				height: rag.dimensions.height * TILE_SIZE,
			};
			const shouldNotContinue =
				(rag.level === 'region' || rag.level === 'cluster') &&
				clusteringLevel === levelNumber['platform'];

			if (isPseudoRag(rag)) {
				if (clusteringLevel >= levelNumber['cube']) return; // Do not render pseudo rags at all, if no clustering

				const { parent } = rag;

				if (
					clusteringLevel < levelNumber['namespace'] &&
					parent?.level === 'cluster' &&
					parent?.name !== '0'
				)
					return;

				if (!parent || isPseudoRag(parent)) {
					// For typescript
					throw new Error('Impossible condition: pseudo rag does not have parent');
				}

				const parentHasBorders = parent.level === 'namespace' || parent.level === 'customGroup';
				if (parentHasBorders) return; // Do not render - parent is visible

				// TODO SMAT-2877 looks ugly
				const parentName = getRagName(parent);
				const pseudoRagName = parentHasBorders
					? parentName
					: rag.data[0].cluster === '0'
					? 'Data stores'
					: rag.data[0].cluster
					? 'Services'
					: 'External';

				let showSearched = false;
				let showSelected = false;
				switch (true) {
					case clusteringLevel === levelNumber['platform']:
						break;
					case clusteringLevel === levelNumber['cluster']:
					case clusteringLevel === levelNumber['namespace']:
						showSearched = childSearched;
						showSelected = childSelected;
						break;
					case clusteringLevel === levelNumber['cube']:
						showSearched = childSearched;
						break;
					case clusteringLevel === levelNumber['label']:
						break;
					default:
				}

				result.push(
					<UniversalRag
						key={rag.id}
						text={pseudoRagName}
						textOnCenter={true}
						theme="transparent"
						sensitivity={sensitivity}
						searched={showSearched}
						selected={showSelected}
						faded={!childFiltered}
						scaleStep={scaleStep}
						position={position}
					/>
				);

				return; // Pseudo rags terminate traversal
			}

			if (rag.level === props.groupBy) {
				const textOnCenter =
					(props.groupBy === 'cluster' ||
						getClusterName(rag) === 'Data stores' ||
						props.groupBy === 'region') &&
					clusteringLevel === levelNumber['platform'];

				const outerBorder = clusteringLevel === levelNumber['platform'] ? childSearched : false;
				const innerBorder = clusteringLevel === levelNumber['platform'] ? childSelected : false;

				result.push(
					<Platform
						key={rag.id}
						sensitivity={clusteringLevel === levelNumber['platform'] ? sensitivity : undefined}
						text={getClusterName(rag)}
						textOnCenter={textOnCenter}
						faded={!childFiltered}
						position={position}
						hideK8Icon={getClusterName(rag) === 'Data stores' || props.groupBy === 'region'}
						outerBorder={outerBorder}
						innerBorder={innerBorder}
					/>
				);
			} else if (rag.level === '_root') {
				// Do not render root rag
			} else if (rag.level === 'clusterWithExternal' || rag.level === 'allClusters') {
				// Do not render cluster wrapper that exists just to group external assets to their clusters
				// TODO these levels actually do not exist, because of 'collapseLevels()'. Can we robustly put this information into our types somehow?
			} else {
				const viewportX = -translate.y / scale;
				const viewportY = -translate.x / scale;
				const viewportX2 = viewportX + window.innerHeight / scale;
				const viewportY2 = viewportY + window.innerWidth / scale;

				const positionIso = {
					left: getIsoPoint(position.left, position.top + position.height)[0],
					top: getIsoPoint(position.left, position.top)[1],
					right: getIsoPoint(position.left + position.width, position.top)[0],
					bottom: getIsoPoint(position.left + position.width, position.top + position.height)[1],
				};

				const visible =
					positionIso.right > viewportY &&
					positionIso.bottom > viewportX &&
					positionIso.left < viewportY2 &&
					positionIso.top < viewportX2;

				let theme: 'sunset' | 'ice' | 'light-ice' | 'rock' | 'transparent';
				switch (rag.level) {
					case 'customGroup':
						theme = 'sunset';
						break;

					case 'namespace':
						theme = 'light-ice';
						break;

					case 'cluster':
						theme = 'ice';
						break;

					default:
						theme = 'transparent';
						break;
				}

				let showSearched = false;
				let showSelected = false;
				let showSensitivity = false;
				switch (true) {
					case clusteringLevel === levelNumber['platform']:
						showSensitivity = true;
						break;
					case clusteringLevel === levelNumber['cluster']:
						showSearched = childSearched;
						showSelected = childSelected;
						showSensitivity = true;
						break;
					case clusteringLevel === levelNumber['namespace'] && rag.level === 'cluster':
						showSensitivity = false;
						break;
					case clusteringLevel === levelNumber['namespace'] && rag.level === 'namespace':
						showSearched = childSearched;
						showSelected = childSelected;
						showSensitivity = true;
						break;
					case clusteringLevel === levelNumber['cube'] && rag.level === 'cluster':
						showSensitivity = false;
						break;
					case clusteringLevel === levelNumber['cube'] && rag.level === 'namespace':
						showSearched = childSearched;
						showSensitivity = false;
						break;
					case clusteringLevel === levelNumber['label']:
						showSensitivity = false;
						break;
					default:
				}

				const emptyCluster = rag.level === 'cluster' && rag.name === '0';
				if (
					visible &&
					!emptyCluster &&
					!(clusteringLevel === levelNumber.cluster && rag.level === 'namespace')
				) {
					if (rag.level !== 'cluster' || rag.name !== '0') {
						result.push(
							<UniversalRag
								key={rag.id}
								text={getRagName(rag)}
								textOnCenter={
									(clusteringLevel === levelNumber['namespace'] && rag.level === 'namespace') ||
									(clusteringLevel === levelNumber.cluster && rag.level === 'cluster')
								}
								theme={theme}
								sensitivity={showSensitivity ? sensitivity : undefined}
								selected={showSelected}
								searched={showSearched}
								faded={!childFiltered}
								onClick={(e: MouseEvent) => {
									e.stopPropagation();
									onRagSelect(rag);
								}}
								scaleStep={scaleStep}
								position={position}
							/>
						);
					}
				}
			}

			!shouldNotContinue && rag.children.forEach(traverse);
		}

		traverse(ragsAndCubes);

		return result;
	}, [
		ragsAndCubes,
		scale,
		scaleStep,
		translate,
		selected,
		mapControls,
		showAssetSensitivity,
		clusteringLevel,
	]);

	return (
		<>
			<div className={styles.ragsLayerWrapper}>
				<div
					className={cn(
						styles.ragsLayer,
						`scaleStep${scaleStep}`,
						showAssetSensitivity && 'showSensitivity'
					)}
				>
					{ragsList}
				</div>
			</div>
		</>
	);
}

export default RagsLayer;
