import { get, post } from 'api/Api';
import { ApiParams } from 'services/api';
import checkResponse from 'services/api/checkResponse';
import {
	NoSQLDatabaseDetails,
	NoSQLDatabaseInstanceList,
	NoSQLDatabaseItem,
	NoSQLDatabaseList,
	NoSQLDatabaseCollectionList,
	NoSQLDatabaseCollectionDetails,
	NoSQLDatabaseCollectionItem,
	NoSQLDatabaseSchemaItem,
	NoSQLDatabaseSchemasList,
	NoSQLDatabaseSchemaDetails,
	NoSQLDatabaseSamplesList,
	NoSQLDatabaseSampleDetails,
} from './dto';
import {
	noSQLDatabaseInstanceListExample,
	noSQLDatabaseListExample,
	noSQLDatabaseCollectionListExample,
	noSQLDatabaseDetailsExample,
	noSQLDatabaseCollectionDetailsExample,
	noSQLDatabaseSchemaListExample,
	noSQLDatabaseSchemaDetailsExample,
	noSQLDatabaseSampleListExample,
	noSQLDatabaseSampleDetailsExample,
} from './examples';

const API_URL = 'v1/nosql-db';

/* Instances */
export async function getNoSQLDatabaseInstanceList() {
	return get<NoSQLDatabaseInstanceList>(`${API_URL}/instances`).then(
		checkResponse(noSQLDatabaseInstanceListExample)
	);
}

/* Databases */
export async function getNoSQLDatabaseList(apiParams: ApiParams, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<NoSQLDatabaseList>(`${API_URL}/databases`, apiParams, config).then(
		checkResponse(noSQLDatabaseListExample)
	);
}

export async function getNoSQLDatabaseDetails(id: NoSQLDatabaseItem['id']) {
	return get<NoSQLDatabaseDetails>(`${API_URL}/databases/${id}`).then(
		checkResponse(noSQLDatabaseDetailsExample)
	);
}

export async function updateNoSQLDatabaseDetails({ id, ...payload }: NoSQLDatabaseDetails) {
	return post<NoSQLDatabaseDetails>(`${API_URL}/databases/${id}`, payload).then(
		checkResponse(noSQLDatabaseDetailsExample)
	);
}

/* Collections */
export async function getNoSQLDatabaseCollectionList(
	id: NoSQLDatabaseItem['id'],
	apiParams: ApiParams,
	signal?: AbortSignal
) {
	const config = signal ? { signal } : {};

	return get<NoSQLDatabaseCollectionList>(
		`${API_URL}/databases/${id}/collections`,
		apiParams,
		config
	).then(checkResponse(noSQLDatabaseCollectionListExample));
}

export async function getNoSQLCollectionDetails(id: NoSQLDatabaseCollectionItem['id']) {
	return get<NoSQLDatabaseCollectionDetails>(`${API_URL}/collections/${id}`).then(
		checkResponse(noSQLDatabaseCollectionDetailsExample)
	);
}

export async function updateNoSQLCollectionDetails({
	id,
	...payload
}: NoSQLDatabaseCollectionDetails) {
	return post<NoSQLDatabaseCollectionDetails>(`${API_URL}/collections/${id}`, payload).then(
		checkResponse(noSQLDatabaseCollectionDetailsExample)
	);
}

/* Schemas */
export async function getNoSQLDatabaseSchemasList(
	id: NoSQLDatabaseCollectionItem['id'],
	apiParams: ApiParams,
	signal?: AbortSignal
) {
	const config = signal ? { signal } : {};

	return get<NoSQLDatabaseSchemasList>(
		`${API_URL}/collections/${id}/schemas`,
		apiParams,
		config
	).then(checkResponse(noSQLDatabaseSchemaListExample));
}

export async function getNoSQLDatabaseMessageSchema(schemaId: number, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<NoSQLDatabaseSchemaDetails>(`${API_URL}/schemas/${schemaId}`, undefined, config).then(
		checkResponse(noSQLDatabaseSchemaDetailsExample)
	);
}

/* Samples */
export async function getNoSQLDatabaseSamplesList(
	id: NoSQLDatabaseSchemaItem['id'],
	apiParams: ApiParams,
	signal?: AbortSignal
) {
	const config = signal ? { signal } : {};

	return get<NoSQLDatabaseSamplesList>(`${API_URL}/schemas/${id}/samples`, apiParams, config).then(
		checkResponse(noSQLDatabaseSampleListExample)
	);
}

export async function getNoSQLDatabaseSample(id: number, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<NoSQLDatabaseSampleDetails>(`${API_URL}/samples/${id}`, undefined, config).then(
		checkResponse(noSQLDatabaseSampleDetailsExample)
	);
}
