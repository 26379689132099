import { createStore } from 'effector';
import { DatabaseInstanceBase, DatabaseInstanceList } from './dto';
import { getDatabaseInstancesFx } from './effects';

const databaseInstancesStore = createStore<DatabaseInstanceBase[]>([]);

databaseInstancesStore.on(getDatabaseInstancesFx.doneData, (_, data: DatabaseInstanceList) => {
	return data.instances.map(({ id, name, type, is_crawler_attached, is_encrypted, region }) => {
		return {
			id,
			name,
			type,
			is_crawler_attached,
			is_encrypted,
			region,
		};
	});
});

const databaseInstancesById = databaseInstancesStore.map((instances) => {
	return instances.reduce(
		(acc, ins) => {
			acc[ins.id] = ins;

			return acc;
		},
		{} as {
			[key: DatabaseInstanceBase['id']]: DatabaseInstanceBase;
		}
	);
});

export { databaseInstancesStore, databaseInstancesById };
