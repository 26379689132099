import cn from 'classnames';
import { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import Preloader from 'components/Preloader';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridHead from 'components/table/GridHead';
import GridRow from 'components/table/GridRow';
import GridTable from 'components/table/GridTable';
import Typo from 'components/typography/Typo';
import { getDatabaseTableList } from 'models/databases/api';
import { DatabaseDetails, DatabaseTableItem } from 'models/databases/dto';
import { PATHS } from 'services/router';
import { unselectInteractsWith } from '../../model/store';
import commonStyles from '../index.module.css';
import styles from './index.module.pcss';

type Props = {
	databaseId: DatabaseDetails['id'];
};

export function Tables({ databaseId }: Props) {
	const [tableList, setTableList] = useState<DatabaseTableItem[]>([]);
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	useEffect(() => {
		setLoading(true);

		getDatabaseTableList({
			database_ids: databaseId,
			limit: 100,
			offset: 0,
		})
			.then(({ tables }) => setTableList(tables))
			.catch((error) => {
				console.error('Failed to fetch database table list:', error);
			})
			.finally(() => {
				setLoading(false);
			});

		return unselectInteractsWith;
	}, [databaseId]);

	return (
		<div className={commonStyles.detailsContainer}>
			<Preloader isLoading={loading}>
				<div className={cn(commonStyles.sidePaddings, commonStyles.scrollContainer)}>
					<GridTable className={commonStyles.table}>
						<GridHead>
							<GridRow className={cn(styles.dataflowRowContainer, styles.gridHeaderRow)} border>
								<GridCell head className={styles.gridCellHead}>
									name
								</GridCell>

								<GridCell head className={styles.gridCellHead}>
									data types
								</GridCell>
							</GridRow>
						</GridHead>

						<GridBody className={styles.tableBody}>
							{tableList.map(({ name, schema_name, data_types, id }, i) => {
								return (
									<GridRow
										key={i}
										className={styles.dataflowRowContainer}
										onClick={() => {
											const path = generatePath(PATHS.DATABASES_TABLE_SAMPLE, {
												sampleId: id,
											});
											history.push(path);
										}}
										border
										hover
									>
										<GridCell className={cn(styles.gapCell, styles.tableNameCell)}>
											{schema_name && (
												<>
													<Typo variant="D/Medium/Meta" component="span">
														{schema_name}
													</Typo>
													.
												</>
											)}
											<Typo variant="D/Medium/Meta" component="span">
												{name}
											</Typo>
										</GridCell>

										<GridCell>
											<PiiTypeList noHighlight data={data_types} size="extraSmall" />
										</GridCell>
									</GridRow>
								);
							})}
						</GridBody>

						<Button
							color="tertiary"
							size="small"
							endIcon={<Icon name="Open/Regular" size={20} />}
							fullWidth
							href={generatePath(PATHS.DATABASES_ITEM, { id: databaseId })}
							target="_blank"
							className={commonStyles.detailedInfo}
						>
							View database page
						</Button>
					</GridTable>
				</div>
			</Preloader>
		</div>
	);
}
