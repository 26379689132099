import { generatePath } from 'react-router';
import { databasesSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { DatabaseInstanceBase, DatabaseTableSample } from 'models/databases/dto';
import { PATHS } from 'services/router';
import { getStringDividedByDot } from 'services/strings';

type Props = {
	sample: Pick<
		DatabaseTableSample,
		'database_id' | 'database_name' | 'table_name' | 'table_schema_name'
	> | null;
	instance: DatabaseInstanceBase | null;
};

function DatabaseSampleHeader({ sample, instance }: Props) {
	if (!sample) return null;

	const { table_name, table_schema_name, database_id, database_name } = sample;

	const steps = [
		...databasesSteps,
		{
			path: generatePath(PATHS.DATABASES_ITEM, {
				id: database_id,
				tab: 'summary',
			}),
			name: database_name,
		},
		{
			path: generatePath(PATHS.DATABASES_ITEM, {
				id: database_id,
				tab: 'tables',
			}),
			name: 'Tables',
		},
	];
	const tableWithSchemaName = table_schema_name ? `${table_schema_name}.${table_name}` : table_name;

	return (
		<Header>
			<Header.Breadcrumbs steps={steps} finalStep={tableWithSchemaName} />

			<Header.Title>{tableWithSchemaName}</Header.Title>
			{instance && (
				<Header.Subtitle>{getStringDividedByDot(instance.name, instance.type)}</Header.Subtitle>
			)}

			<Header.SensitiveSwitcher />
		</Header>
	);
}

export { DatabaseSampleHeader };
