import styles from './index.module.css';

/* Initialize debug settings */
const queryParams = new URLSearchParams(window.location.search);

const debugSettings = JSON.parse(sessionStorage.getItem('svrn-debug') || '{}');

if (queryParams.has('debug')) {
	debugSettings.enabled = true;
}

if (queryParams.has('FLAG_PERFORMANCE')) {
	debugSettings.FLAG_PERFORMANCE = true;
}

if (queryParams.has('FLAG_AI_ASSISTANCE')) {
	debugSettings.FLAG_AI_ASSISTANCE = true;
}

if (queryParams.has('FLAG_AI_ASSISTANCE_SYSTEM')) {
	debugSettings.FLAG_AI_ASSISTANCE_SYSTEM = true;
}

sessionStorage.setItem('svrn-debug', JSON.stringify(debugSettings));

/* Component */

function DebugOverlay() {
	if (!debugSettings.enabled) {
		return null;
	}

	function onClose() {
		sessionStorage.setItem('svrn-debug', '{}');
	}

	return (
		<div className={styles.container}>
			<p>
				Debug info{' '}
				<a href="/" onClick={onClose}>
					[clear all flags]
				</a>
			</p>

			<p>
				Active flags: [{debugSettings.FLAG_AI_ASSISTANCE_SYSTEM && 'FLAG_AI_ASSISTANCE_SYSTEM'}{' '}
				{debugSettings.FLAG_AI_ASSISTANCE && 'FLAG_AI_ASSISTANCE'}{' '}
				{debugSettings.FLAG_PERFORMANCE && 'FLAG_PERFORMANCE'}]
			</p>
		</div>
	);
}

export { debugSettings };
export default DebugOverlay;
