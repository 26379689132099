import { useStore } from 'effector-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { generatePath } from 'react-router';
import { useImmer } from 'use-immer';
import Alert, { AlertSeverity } from 'components/Alert';
import { CurtainOverlay } from 'components/Curtain';
import TextField from 'components/form/TextField';
import { enqueueSnackbar } from 'components/Snackbar';
import Typo from 'components/typography/Typo';
import { externalAssetsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { AssetDetailJson } from 'models/assets/dto';
import { assetsList } from 'models/assets/store';
import { APIError } from 'services/api/httpRequest';
import { goBackByDefault } from 'services/history';
import { PATHS } from 'services/router';
import { validateIP } from 'services/validation';
import { AssetFormFooter } from './AssetFormFooter';
import AssetGroups from './AssetGroups';
import AssetLabels from './AssetLabels';
import styles from './index.module.css';
import RuleBuilder from './RuleBuilder';
import UsefulTipsCurtain from './UsefulTipsCurtain';

interface Props {
	asset: AssetDetailJson;
	updateAsset: (asset: AssetDetailJson) => Promise<unknown>;
}

const ALREADY_EXIST_REGEXP = /name/;

function AssetInfo({ asset, updateAsset }: Props) {
	const assets = useStore(assetsList);
	const otherInLink = useMemo(() => {
		const id = assets.find((a) => a.type === 'other_in')?.id || -1;

		return id === -1
			? generatePath(PATHS.EXTERNAL_CONNECTIONS)
			: generatePath(PATHS.SERVICE_ITEM, { id, tab: 'recipients' });
	}, [assets]);

	const [formData, setFormData] = useImmer(asset);
	const [assetNameError, setAssetNameError] = useState('');
	const [curtainOpen, setCurtainOpen] = useState(true);

	const isNew = asset.id === 0;
	const isInternal = asset.type === 'internal';
	const isCustom = asset.type === 'custom';

	const setName = useCallback((name) => {
		setFormData((draft) => {
			draft.name = name;
		});

		if (name.length > 255) {
			setAssetNameError('Maximum Name length is 255');
		} else {
			setAssetNameError('');
		}
	}, []);

	const setDescription = useCallback((description) => {
		setFormData((draft) => {
			draft.description = description;
		});
	}, []);

	const setOwner = useCallback((owner) => {
		setFormData((draft) => {
			draft.owner = owner;
		});
	}, []);

	const scrollRef = useRef<HTMLDivElement>(null);
	const scrollToTop = () => {
		if (scrollRef.current) {
			scrollRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	function checkForRules() {
		return formData.rules.every(
			(complexRule) =>
				complexRule.length > 0 &&
				complexRule.every(
					(simpleRule) =>
						simpleRule.values.length > 0 &&
						(simpleRule.type !== 'ip' || simpleRule.values.every(validateIP))
				)
		);
	}

	const saveAsset = async () => {
		if (formData.name.length > 255) {
			enqueueSnackbar('Maximum Name length is 255');
			return;
		}

		if (isCustom && formData.rules.length === 0) {
			enqueueSnackbar('Add rules');
			return;
		}

		if (isCustom && !checkForRules()) {
			enqueueSnackbar('Check rules for errors');
			return;
		}

		try {
			await updateAsset(formData);
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();

				if (ALREADY_EXIST_REGEXP.test(message)) {
					setAssetNameError(message);
					scrollToTop();
				} else {
					enqueueSnackbar(message);
				}
				return;
			}

			throw error;
		}

		enqueueSnackbar(isNew ? 'Connection was saved' : 'Connection was updated');
		goBackByDefault(PATHS.EXTERNAL_CONNECTIONS);
	};

	function onRulesChange(newRules: AssetDetailJson['rules']) {
		setFormData((draft) => {
			draft.rules = newRules;
		});
	}

	return (
		<div className={styles.container} data-test="asset-info-container" ref={scrollRef}>
			{isNew && (
				<Header>
					<Header.Breadcrumbs
						steps={externalAssetsSteps}
						finalStep="New external custom connection"
					/>

					<Header.Title>New external custom connection</Header.Title>
				</Header>
			)}

			<div className={styles.textFieldList}>
				{isCustom && <Typo variant="D/Medium/H100-Header">External custom connection details</Typo>}

				{isCustom && (
					<TextField
						fullWidth
						value={formData.name}
						onChange={({ target }) => setName(target.value)}
						size="medium"
						label="Name"
						placeholder="Enter name, e.g. “Supplier”"
						helperText={assetNameError}
						error={!!assetNameError}
						required
						data-test="asset-info-name-input"
					/>
				)}

				<TextField
					fullWidth
					value={formData.description}
					onChange={({ target }) => setDescription(target.value)}
					size="medium"
					label="Description"
					placeholder="Service description"
					helperText={null}
					multiline
					minRows={2}
					optional={false}
					data-test="asset-info-description-input"
				/>

				<TextField
					fullWidth
					value={formData.owner}
					onChange={({ target }) => setOwner(target.value)}
					size="medium"
					label="Owner"
					placeholder="Owner’s name"
					helperText={null}
					optional={false}
					data-test="asset-info-owner-input"
				/>
			</div>

			{isInternal && <AssetLabels asset={formData} />}

			{isInternal && <AssetGroups asset={formData} />}

			{isCustom && (
				<>
					<div className={styles.ruleBuilder}>
						<Typo variant="D/Medium/H100-Header" className={styles.ruleBuilderHeader}>
							Recipients included
						</Typo>
						<Typo variant="D/Regular/Body-S" className={styles.ruleBuilderText}>
							Use IP addresses and User Agent (UA) strings from{' '}
							<a href={otherInLink} target="_blank" rel="noopener">
								Other incoming
							</a>
							. Once it&apos;s configured and saved, all new traffic matching the set IP + UA pair
							will be re-directed to the new service.
						</Typo>
						<Typo variant="D/Regular/Body-S" className={styles.ruleBuilderText}>
							The new custom connection will capture only new events. Previously observed events
							will remain in{' '}
							<a href={otherInLink} target="_blank" rel="noopener">
								Other incoming
							</a>{' '}
							for about a week before they phase out.
						</Typo>
						<RuleBuilder initialRules={formData.rules} onChange={onRulesChange} />
						<CurtainOverlay
							open={curtainOpen}
							onClose={() => setCurtainOpen(false)}
							onOpen={() => setCurtainOpen(true)}
							rightPart={<UsefulTipsCurtain otherInLink={otherInLink} />}
							classes={{
								rightPart: styles.curtain,
							}}
						/>
					</div>

					<Alert
						severity={AlertSeverity.info}
						className={styles.infoAlert}
						header="Only new traffic will be tracked"
					>
						<Typo variant="D/Regular/Body-S">
							After saving the edits, only future API requests
							<br />
							will be checked for violations (i.e., potential violations
							<br />
							based on previous data requests will not be shown).
						</Typo>
					</Alert>
				</>
			)}

			<AssetFormFooter isNew={isNew} isError={!!assetNameError} saveForm={saveAsset} />
		</div>
	);
}

export default AssetInfo;
