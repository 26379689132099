import { AssetJson } from 'models/assets/dto';
import { Cube, MapAsset, RagsAndCubesTree } from '../index';
import { TILE_SIZE } from '../IsometricMap';
import { HACK_PLACEMENT_X, HACK_PLACEMENT_Y } from '../IsometricMap/CubesLayer';
import { isPseudoRag } from './listToTreeByAttributes';

function getIsoPoint(x: number, y: number) {
	return [x * 0.707107 + y * -0.707107, x * 0.40558 + y * 0.40558];
}

const getCubesFromRagsTree = (ragsAndCubes: RagsAndCubesTree) => {
	let result: Cube[] = [];

	function traverse(rag: RagsAndCubesTree) {
		if (isPseudoRag(rag)) {
			result = result.concat(rag.data);
		} else {
			rag.children.forEach(traverse);
		}
	}

	traverse(ragsAndCubes);

	return result;
};

type Props = {
	id: number;
	types: MapAsset['type'][];
	mapAssetsMap: Map<AssetJson['id'], MapAsset>;
	ragsAndCubes: RagsAndCubesTree;
};

export const getCubePosition = (props: Props) => {
	const { id, types, mapAssetsMap, ragsAndCubes } = props;

	const cubes = getCubesFromRagsTree(ragsAndCubes);
	const elements = cubes
		.filter((cube) => cube.elementId === id)
		.map((cube) => {
			const mapAssetElement = mapAssetsMap.get(cube.elementId);

			return {
				id: mapAssetElement?.elementId,
				type: mapAssetElement?.type,
				top: cube.dimensions.absoluteY,
				left: cube.dimensions.absoluteX,
			};
		});
	const element = elements.find((el) => {
		return el.id === id && types.includes(el.type!);
	});

	if (element) {
		const [x, y] = getIsoPoint(element.left * TILE_SIZE, element.top * TILE_SIZE);
		return {
			top: y + HACK_PLACEMENT_Y,
			left: x + HACK_PLACEMENT_X,
		};
	}

	return undefined;
};
