import { IHeadCell } from 'components/table/EnhancedTableHead';
import { DatabaseRolePermission } from 'models/databases/dto';

const tableConfig: IHeadCell<DatabaseRolePermission>[] = [
	{
		id: 'table_name',
		numeric: false,
		label: 'Table name',
		sortable: false,
	},
	{
		id: 'permissions',
		numeric: false,
		label: 'Privileges',
		sortable: false,
	},
];

export default tableConfig;
