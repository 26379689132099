import { useEffect, useState } from 'react';
import { ConfirmModal, getConfirmation } from 'components/ConfirmModal';
import Button from 'components/form/Button';
import Preloader from 'components/Preloader';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { deleteSlackIntegration, getSlackSettings } from 'models/integrations/slack/api';
import { SlackSettings } from 'models/integrations/slack/dto';
import { PATHS } from 'services/router';
import integratonsStyles from '../index.module.css';
import SlackSVG from '../source/slack.svg';

const CLIENT_ID = '4745812875874.4742899308085';
const SCOPE = ['channels:read', 'chat:write', 'chat:write.public'].join(',');
const STORAGE_KEY = 'svrn-slack-oauth-state';

const initialSettings: SlackSettings = {
	has_token: false,
	is_enabled: false,
	channel: '',
	notify: [],
};

function Slack() {
	const [isLoading, setLoading] = useState(true);
	const [settings, setSettings] = useState<SlackSettings>(initialSettings);

	const connected = settings.has_token;

	useEffect(() => {
		getSlackSettings().then((slackSettings) => {
			setSettings(slackSettings);
			setLoading(false);
		});
	}, []);

	function generateStateAndRedirect() {
		const state = `random-${Math.random()}`;

		localStorage.setItem(STORAGE_KEY, state);
		const url = `https://slack.com/oauth/v2/authorize?scope=${SCOPE}&client_id=${CLIENT_ID}&state=${state}`;

		window.location.href = url;
	}

	async function confirmDisconnect() {
		const confirmResolveModal = (
			<ConfirmModal
				title="Disconnect Slack"
				confirmProps={{
					children: 'Disconnect',
				}}
			>
				If you disconnect Slack, you will stop receiving notifications of events in your Slack.
			</ConfirmModal>
		);

		return await getConfirmation(confirmResolveModal);
	}

	async function disconnect() {
		const confirmed = await confirmDisconnect();

		if (!confirmed) return;

		deleteSlackIntegration().then(getSlackSettings).then(setSettings);
	}

	const actionButtons = connected ? (
		<>
			<RouterLink to={PATHS.INTEGRATIONS_SLACK}>
				<Button size="small" color="secondary">
					Edit
				</Button>
			</RouterLink>

			<Button size="small" color="ghost" onClick={disconnect}>
				Disconnect
			</Button>
		</>
	) : (
		<Button size="small" color="primary" onClick={generateStateAndRedirect}>
			Connect
		</Button>
	);

	return (
		<div className={integratonsStyles.block}>
			<Preloader isLoading={isLoading}>
				<div className={integratonsStyles.top}>
					<SlackSVG />

					{connected && (
						<Typo variant="D/Medium/Body-S" className={integratonsStyles.connected}>
							Connected
						</Typo>
					)}
				</div>

				<div className={integratonsStyles.middle}>
					<div className={integratonsStyles.name}>
						<Typo variant="D/Medium/H100-Header">Slack</Typo>
					</div>

					<Typo variant="D/Regular/Body-S" className={integratonsStyles.description}>
						Send notifications to a Slack channel or user.
					</Typo>
				</div>

				<div className={integratonsStyles.bottom}>{actionButtons}</div>
			</Preloader>
		</div>
	);
}

export default Slack;
export { STORAGE_KEY };
