import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { piiFilterStore } from 'components/PiiGlobalFilterV2/model';
import { AssetJson, assetTypes } from 'models/assets/dto';
// import { assetsToDM } from 'models/assets/generator';
import { getAssetsFx } from 'models/assets/effects';
import { getAssetNameByType } from 'models/assets/model';
import { ClusterGeoLocationRegionItem } from 'models/clusterGeoLocations/dto';
import { getClusterGeoLocationRegionsFx } from 'models/clusterGeoLocations/effects';
import { TSensitivity } from 'models/common/dto';
import { getDataMap } from 'models/dataMapV2/api';
import { Element, Relation, TLevel } from 'models/dataMapV2/dto';
import { DataTypeItem } from 'models/dataTypes/dto';
import { getNamespacesFx } from 'models/namespaces/effects.ts';
import DataMapPlaceholder from './DataMapPlaceholder';
import IsometricMap from './IsometricMap';
import { mapControlsStore } from './IsometricMap/model/store';
import addDimensionPropertyToTree, { Dimensions } from './utils/addDimensionPropertyToTree';
import addParentPropertyToTree from './utils/addParentPropertyToTree';
import arrangeRectangles from './utils/arrangeRectangles';
import calculateAbsoluteDimensions from './utils/calculateAbsoluteDimensions';
import collapseLevels from './utils/collapseLevels';
import listToTreeByAttributes, { Rag } from './utils/listToTreeByAttributes';

const LEVELS_CLUSTER_NAMESPACE: TLevel[] = [
	'allClusters',
	'clusterWithExternal',
	'cluster',
	'namespace',
];
const LEVELS_CLUSTER_GROUP: TLevel[] = [
	'allClusters',
	'clusterWithExternal',
	'cluster',
	'customGroup',
];
const LEVELS_REGION_NAMESPACE: TLevel[] = [
	'allClusters',
	'region',
	'clusterWithExternal',
	'cluster',
	'namespace',
];
const LEVELS_REGION_GROUP: TLevel[] = [
	'allClusters',
	'region',
	'clusterWithExternal',
	'cluster',
	'customGroup',
];

type ProtoCube = {
	id: number;
	elementId: number;
	sensitivity: TSensitivity;
	// Grouping by
	allClusters: string;
	cluster: string;
	clusterWithExternal: string;
	region: string;
	customGroup: string;
	namespace: string;
};

type Cube = ProtoCube & Dimensions & Parent;

// Here we duplicate and add levels fields, such as 'cloud' or 'customGroup'
function prepareCubesForArranging(
	elements: MapAsset[],
	geoLocationRegions: { [key: string]: ClusterGeoLocationRegionItem }
): [ProtoCube[], ProtoCube[]] {
	let cubeIdx = 0;

	function createCube(asset: MapAsset, groupId: number = 0) {
		const isInternal = asset.type === 'internal' || asset.type === 'external_namespace';
		const isCustom = asset.type === 'custom';

		const protoCube: ProtoCube = {
			id: cubeIdx++,
			elementId: asset.elementId,
			sensitivity: asset.sensitivity,

			allClusters: asset.cluster_id === 0 ? '0' : '1',
			cluster: isInternal ? String(asset.cluster_id) : '',
			clusterWithExternal: String(asset.cluster_id),
			region: geoLocationRegions[asset.region]?.description || (isCustom ? '' : 'Other regions'),
			customGroup: groupId ? String(groupId) : '',
			namespace: asset.namespace,
		};

		return protoCube;
	}

	const createCubeService = (service: MapAsset) => {
		const protoCube: ProtoCube = {
			id: cubeIdx++,
			elementId: service.elementId,
			sensitivity: service.sensitivity,

			allClusters: '1',
			cluster: '0',
			clusterWithExternal: String(service.cluster_id),
			region: geoLocationRegions[service.region]?.description || 'Other regions',
			customGroup: '',
			namespace: '',
		};

		return protoCube;
	};

	const cubesByNamespace = elements.map((element) => {
		return element.type === 's3_bucket' ||
			element.type === 'kafka_instance' ||
			element.type === 'sql_db_database' ||
			element.type === 'nosql_db_database'
			? createCubeService(element)
			: createCube(element);
	});

	// There can and will be duplicate asset ids here, by design. But not duplicate cube ids, which is the whole point.
	// const assetElements = elements.filter((element) => element.type === 'asset') as AssetElement[];
	// const cubesByCustomGroup = assetElements.flatMap((element) => {
	const cubesByCustomGroup = elements.flatMap((element) => {
		if (
			element.type === 's3_bucket' ||
			element.type === 'kafka_instance' ||
			element.type === 'sql_db_database' ||
			element.type === 'nosql_db_database'
		) {
			return createCubeService(element);
		} else if (element.groups.length === 0) {
			return createCube(element);
		} else {
			return element.groups.map((groupId) => createCube(element, groupId));
		}
	});

	return [cubesByNamespace, cubesByCustomGroup];
}

// Everything data map should know about asset
// TODO ???: need to separate MapAsset to MapService and MapS3Bucket
type MapAsset = {
	elementId: number; // Individual and unique id for element, that participate with searching, filtering and cube clicking
	entityId: AssetJson['id'];
	type:
		| AssetJson['type']
		| 's3_bucket'
		| 'kafka_instance'
		| 'sql_db_database'
		| 'nosql_db_database'
		| 'vm';
	name: AssetJson['name'];
	namespace: AssetJson['namespace'];
	k8s_types: AssetJson['k8s_types'];
	groups: AssetJson['groups'];
	cluster_id: AssetJson['cluster_id'];
	region: ClusterGeoLocationRegionItem['keyword'];
	// region: S3RegionItem['keyword'];
	labels: AssetJson['labels'];
	sensitivity: TSensitivity;
	owner: AssetJson['owner'];
	description: AssetJson['description'];
	dataTypes: DataTypeItem['id'][];
	dataFlowsFrom: {
		id: AssetJson['id'];
		dataTypes: DataTypeItem['id'][];
		connectionType: 'dataflow' | 'connection';
		is_encrypted: boolean;
		is_mesh_network: boolean;
	}[];
	dataFlowsTo: {
		id: AssetJson['id'];
		dataTypes: DataTypeItem['id'][];
		connectionType: 'dataflow' | 'connection';
		is_encrypted: boolean;
		is_mesh_network: boolean;
	}[];
};

type Parent = { parent: RagsAndCubesTree | null };
type RagsAndCubesTree = Rag<ProtoCube, TLevel | '_root', Dimensions & Parent>;

function relationsByReceiver(type: Element['type'], entityId: AssetJson['id']) {
	return (relation: Relation) =>
		relation.receiver.type === type && relation.receiver.id === entityId;
}
function relationsBySender(type: Element['type'], entityId: AssetJson['id']) {
	return (relation: Relation) => relation.sender.type === type && relation.sender.id === entityId;
}

function prepareAssets(
	elements: Element[],
	relations: Relation[],
	assets: AssetJson[]
): MapAsset[] {
	const preparedMapAssets = elements.map((element, index) => {
		if (element.type === 'asset') {
			const asset = assets.find((assetItem) => assetItem.id === element.id);
			if (!asset) throw new Error('Service not found');

			return {
				elementId: index,
				entityId: element.id,
				type: element.data.type,
				name: getAssetNameByType(asset.type, element.name),
				namespace: element.namespace,
				k8s_types: asset.k8s_types,
				groups: asset.groups,
				cluster_id: asset.cluster_id,
				region: element.region,
				labels: asset.labels,
				sensitivity: element.data.sensitivity,
				owner: asset.owner,
				description: asset.description,
				dataTypes: [],
				dataFlowsFrom: [],
				dataFlowsTo: [],
			};
		} else {
			return {
				elementId: index,
				entityId: element.id,
				type: element.type,
				name: element.name,
				namespace: element.namespace,
				k8s_types: [],
				groups: [],
				cluster_id: element.cluster_id,
				region: element.region,
				labels: [],
				sensitivity: element.data.sensitivity,
				owner: '',
				description: '',
				dataTypes: element.data.data_types,
				dataFlowsFrom: [],
				dataFlowsTo: [],
			};
		}
	});

	return preparedMapAssets.map((element) => {
		if (
			element.type === 'kafka_instance' ||
			element.type === 'sql_db_database' ||
			element.type === 'nosql_db_database'
		) {
			return element;
		} else if (element.type === 's3_bucket') {
			const dataFlowsFrom: MapAsset['dataFlowsFrom'] = relations
				.filter(relationsByReceiver('s3_bucket', element.entityId))
				.map((relation) => {
					const sender = preparedMapAssets.find(
						(mapAsset) =>
							mapAsset.entityId === relation.sender.id && assetTypes.includes(mapAsset.type)
					);

					if (!sender) {
						throw new Error('Data-flows parsing error: sender must exist in relations');
					}

					return {
						id: sender.elementId,
						dataTypes: relation.data_types,
						connectionType: 'connection',
						is_encrypted: relation.is_encrypted,
						is_mesh_network: relation.is_mesh_network,
					};
				});
			const dataFlowsTo: MapAsset['dataFlowsTo'] = relations
				.filter(relationsBySender('s3_bucket', element.entityId))
				.map((relation) => {
					const receiver = preparedMapAssets.find(
						(mapAsset) =>
							mapAsset.entityId === relation.receiver.id && assetTypes.includes(mapAsset.type)
					);

					if (!receiver) {
						throw new Error('Data-flows parsing error: receiver must exist in relations');
					}

					return {
						id: receiver.elementId,
						dataTypes: relation.data_types,
						connectionType: 'connection',
						is_encrypted: relation.is_encrypted,
						is_mesh_network: relation.is_mesh_network,
					};
				});
			return { ...element, dataFlowsFrom, dataFlowsTo };
		} else {
			const dataFlowsFrom: MapAsset['dataFlowsFrom'] = relations
				.filter(relationsByReceiver('asset', element.entityId))
				.map((relation) => {
					const sender = preparedMapAssets.find((mapAsset) => {
						if (relation.sender.type === 'asset') {
							return mapAsset.entityId === relation.sender.id && assetTypes.includes(mapAsset.type);
						} else {
							return (
								mapAsset.entityId === relation.sender.id && mapAsset.type === relation.sender.type
							);
						}
					});

					if (!sender) {
						throw new Error('Data-flows parsing error: sender must exist in relations');
					}

					return {
						id: sender.elementId,
						dataTypes: relation.data_types,
						connectionType: relation.sender.type === 's3_bucket' ? 'connection' : 'dataflow',
						is_encrypted: relation.is_encrypted,
						is_mesh_network: relation.is_mesh_network,
					};
				});
			const dataFlowsTo: MapAsset['dataFlowsTo'] = relations
				.filter(relationsBySender('asset', element.entityId))
				.map((relation) => {
					const receiver = preparedMapAssets.find((mapAsset) => {
						if (relation.receiver.type === 'asset') {
							return (
								mapAsset.entityId === relation.receiver.id && assetTypes.includes(mapAsset.type)
							);
						} else {
							return (
								mapAsset.entityId === relation.receiver.id &&
								mapAsset.type === relation.receiver.type
							);
						}
						// mapAsset.entityId === relation.receiver.id && mapAsset.type === relation.receiver.type
					});

					if (!receiver) {
						throw new Error('Data-flows parsing error: receiver must exist in relations');
					}

					return {
						id: receiver.elementId,
						dataTypes: relation.data_types,
						connectionType: relation.receiver.type === 's3_bucket' ? 'connection' : 'dataflow',
						is_encrypted: relation.is_encrypted,
						is_mesh_network: relation.is_mesh_network,
					};
				});

			const dataTypes = [
				...new Set(dataFlowsFrom.concat(dataFlowsTo).flatMap((dataFlow) => dataFlow.dataTypes)),
			];

			return { ...element, dataTypes, dataFlowsFrom, dataFlowsTo };
		}
	});
}

function DataMap() {
	const { nonEmpty } = useStore(piiFilterStore);
	const { groupByRegionOrCluster, groupByNamespaceOrGroup } = useStore(mapControlsStore);

	const [loadingState, setLoadingState] = useState<'loading' | 'noData' | 'ready'>('loading');

	const [mapAssets, setMapAssets] = useState<{ all: MapAsset[]; sensitive: MapAsset[] }>({
		all: [],
		sensitive: [],
	});
	const [mapAssetsMap, setMapAssetsMap] = useState<{
		all: Map<AssetJson['id'], MapAsset>;
		sensitive: Map<AssetJson['id'], MapAsset>;
	}>({
		all: new Map(),
		sensitive: new Map(),
	});
	const [ragsAndCubes, setRagsAndCubes] = useState<{
		clusterNamespace: {
			all: RagsAndCubesTree;
			sensitive: RagsAndCubesTree;
		};
		clusterGroup: {
			all: RagsAndCubesTree;
			sensitive: RagsAndCubesTree;
		};
		regionNamespace: {
			all: RagsAndCubesTree;
			sensitive: RagsAndCubesTree;
		};
		regionGroup: {
			all: RagsAndCubesTree;
			sensitive: RagsAndCubesTree;
		};
	} | null>(null);

	let groupBy: 'clusterNamespace' | 'clusterGroup' | 'regionNamespace' | 'regionGroup';
	switch (true) {
		case groupByRegionOrCluster === 'cluster' && groupByNamespaceOrGroup === 'namespace': {
			groupBy = 'clusterNamespace';
			break;
		}
		case groupByRegionOrCluster === 'cluster' && groupByNamespaceOrGroup === 'customGroup': {
			groupBy = 'clusterGroup';
			break;
		}
		case groupByRegionOrCluster === 'region' && groupByNamespaceOrGroup === 'namespace': {
			groupBy = 'regionNamespace';
			break;
		}
		case groupByRegionOrCluster === 'region' && groupByNamespaceOrGroup === 'customGroup': {
			groupBy = 'regionGroup';
			break;
		}

		default:
			groupBy = 'clusterNamespace';
	}

	useEffect(function () {
		async function loadAndPrepareData() {
			const [dataMapFromServer, { assets }, { regions }] = await Promise.all([
				getDataMap(),
				getAssetsFx(),
				getClusterGeoLocationRegionsFx(),
				getNamespacesFx(),
			]);

			const clusterGeoLocationsMap = Object.fromEntries(
				regions.map((geoLocation) => [geoLocation.keyword, geoLocation])
			);

			// @ts-ignore DATA-GENERATOR
			// dataMapFromServer.elements.push(...assetsToDM());
			// console.log('dataMapFromServer', JSON.stringify(dataMapFromServer));

			if (dataMapFromServer.elements.length === 0) {
				setLoadingState('noData');
				return;
			}

			const elementsSensitive = dataMapFromServer.elements.filter(
				(element) => element.data.sensitivity !== 'N/A'
			);
			const relationsSensitive = dataMapFromServer.relations.filter((relation) => {
				const hasSender = elementsSensitive.some(
					(el) => el.id === relation.sender.id && el.type === relation.sender.type
				);
				const hasReceiver = elementsSensitive.some(
					(el) => el.id === relation.receiver.id && el.type === relation.receiver.type
				);
				return hasSender && hasReceiver;
			});

			const dataMapFromServerSensitive = {
				...dataMapFromServer,
				elements: elementsSensitive,
				relations: relationsSensitive,
			};

			const mapAssetsCombined = {
				all: prepareAssets(dataMapFromServer.elements, dataMapFromServer.relations, assets),
				sensitive: prepareAssets(
					dataMapFromServerSensitive.elements,
					dataMapFromServerSensitive.relations,
					assets
				),
			};
			const mapAssetsMapAll = new Map();
			const mapAssetsMapSensitive = new Map();

			for (const asset of mapAssetsCombined.all) {
				mapAssetsMapAll.set(asset.elementId, asset);
			}
			for (const asset of mapAssetsCombined.sensitive) {
				mapAssetsMapSensitive.set(asset.elementId, asset);
			}

			const mapAssetsMapCombined = {
				all: mapAssetsMapAll,
				sensitive: mapAssetsMapSensitive,
			};

			const [cubesByNamespace, cubesByCustomGroup] = prepareCubesForArranging(
				mapAssetsCombined.all,
				clusterGeoLocationsMap
			);
			const [cubesByNamespaceSensitive, cubesByCustomGroupSensitive] = prepareCubesForArranging(
				mapAssetsCombined.sensitive,
				clusterGeoLocationsMap
			);

			function createResultTree(
				cubes: ProtoCube[],
				levels: TLevel[],
				platformLevel: TLevel
			): RagsAndCubesTree {
				const platformContentLevel =
					levels[levels.findIndex((value) => value === platformLevel) - 1];
				const notArranged = listToTreeByAttributes(cubes, levels);

				const withDimensions = addDimensionPropertyToTree(notArranged);
				const resultTree: RagsAndCubesTree = addParentPropertyToTree(withDimensions, null);

				arrangeRectangles(
					resultTree,
					['_root', 'clusterWithExternal'],
					platformLevel,
					platformContentLevel
				);

				calculateAbsoluteDimensions(resultTree);

				collapseLevels(resultTree, ['allClusters', 'clusterWithExternal']);

				return resultTree;
			}

			const startLayoutCalc = new Date().getTime();

			const ragsAndCubesCombined = {
				clusterNamespace: {
					all: createResultTree(cubesByNamespace, LEVELS_CLUSTER_NAMESPACE, 'cluster'),
					sensitive: createResultTree(
						cubesByNamespaceSensitive,
						LEVELS_CLUSTER_NAMESPACE,
						'cluster'
					),
				},
				clusterGroup: {
					all: createResultTree(cubesByCustomGroup, LEVELS_CLUSTER_GROUP, 'cluster'),
					sensitive: createResultTree(cubesByCustomGroupSensitive, LEVELS_CLUSTER_GROUP, 'cluster'),
				},
				regionNamespace: {
					all: createResultTree(cubesByNamespace, LEVELS_REGION_NAMESPACE, 'region'),
					sensitive: createResultTree(cubesByNamespaceSensitive, LEVELS_REGION_NAMESPACE, 'region'),
				},
				regionGroup: {
					all: createResultTree(cubesByCustomGroup, LEVELS_REGION_GROUP, 'region'),
					sensitive: createResultTree(cubesByCustomGroupSensitive, LEVELS_REGION_GROUP, 'region'),
				},
			};

			const endLayoutCalc = new Date().getTime();
			console.info(`Map layout calculation took ${endLayoutCalc - startLayoutCalc}ms`);

			setMapAssets(mapAssetsCombined);
			setMapAssetsMap(mapAssetsMapCombined);
			setRagsAndCubes(ragsAndCubesCombined);

			setLoadingState('ready');
		}

		loadAndPrepareData();
	}, []);

	return loadingState === 'ready' ? (
		<IsometricMap
			mapAssets={mapAssets[nonEmpty ? 'sensitive' : 'all']}
			mapAssetsMap={mapAssetsMap[nonEmpty ? 'sensitive' : 'all']}
			ragsAndCubes={ragsAndCubes![groupBy][nonEmpty ? 'sensitive' : 'all']}
			groupBy={groupByRegionOrCluster}
		/>
	) : (
		<DataMapPlaceholder loadingState={loadingState} />
	);
}

export default DataMap;
export type { Cube, MapAsset, RagsAndCubesTree };
