import { useState } from 'react';
import Button from 'components/form/Button';
import TextField from 'components/form/TextField';
import Preloader from 'components/Preloader';
import { SecretToken } from 'components/SecretToken';
import { enqueueSnackbar } from 'components/Snackbar';
import Typo from 'components/typography/Typo';
import { integrationsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { createExternalAPIToken } from 'models/integrations/externalAPI/api';
import { ExternalAPIToken } from 'models/integrations/externalAPI/dto';
import { APIError } from 'services/api/httpRequest';
import { goBackByDefault } from 'services/history';
import { PATHS } from 'services/router';
import { NeedHelpBlock } from 'views/common/NeedHelpBlock';
import styles from './index.module.css';

function CreateToken() {
	const [token, setToken] = useState<ExternalAPIToken['token']>('');
	const [name, setName] = useState<string>('');
	const [loading, setLoading] = useState(false);

	async function onCreate() {
		setLoading(true);

		try {
			await createExternalAPIToken(name).then((payload) => {
				setToken(payload.token);
				enqueueSnackbar('New API token successfully created');
			});
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();
				enqueueSnackbar(message);

				return;
			}

			throw error;
		} finally {
			setLoading(false);
		}
	}

	const creationStep = (
		<>
			<Typo variant="D/Medium/H100-Header">Name the new API token</Typo>

			<Typo variant="D/Regular/Body-S" className={styles.createDescription}>
				Choose a name for the new API token. The name should be descriptive enough for you to
				recognize it if you have many of them.
			</Typo>

			<TextField
				data-test="create-token-name-input"
				fullWidth
				label="API token name"
				value={name}
				onChange={(e) => setName(e.target.value)}
				required
			/>

			<div className={styles.createButtons}>
				<Button
					color="secondary"
					onClick={() => goBackByDefault(PATHS.INTEGRATIONS_EXTERNAL_API)}
					data-test="create-token-cancel-button"
				>
					Cancel
				</Button>

				<Button disabled={!name} onClick={onCreate} data-test="create-token-create-button">
					Create
				</Button>
			</div>
		</>
	);

	const finalStep = (
		<>
			<Typo variant="D/Medium/H100-Header">API token created</Typo>

			<Typo variant="D/Regular/Body-S" className={styles.createDescription}>
				You can always find it later in the list of API tokens.
			</Typo>

			<SecretToken canBeCopied canBeHidden={false} value={token} fullWidth />

			<NeedHelpBlock />

			<div className={styles.createButtons}>
				<Button
					onClick={() => goBackByDefault(PATHS.INTEGRATIONS_EXTERNAL_API)}
					data-test="create-token-ok-button"
				>
					Got it
				</Button>
			</div>
		</>
	);

	return (
		<div className={styles.createContainer}>
			<Header>
				<Header.Breadcrumbs
					steps={[
						...integrationsSteps,
						{ path: PATHS.INTEGRATIONS_EXTERNAL_API, name: 'New API Token' },
					]}
					finalStep="Integrations"
				/>

				<Header.Title>New API Token</Header.Title>
			</Header>

			<Preloader isLoading={loading}>{!token ? creationStep : finalStep}</Preloader>
		</div>
	);
}

export default CreateToken;
