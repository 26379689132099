import { useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Tooltip from 'components/Tooltip';
import { DarObjectStatusLabel, DatabaseTableItem } from 'models/databases/dto';
import { lastSeen } from 'services/lastSeen';
import { toFileSize } from 'services/numbers';
import { PATHS } from 'services/router';
import styles from './index.module.css';

type Props = {
	table: DatabaseTableItem;
};

function TableRow({ table }: Props) {
	const {
		id,
		name,
		schema_name,
		size,
		sensitivity,
		data_types,
		last_checked,
		status: errorStatus,
	} = table;
	const lastSeenValue = useMemo(
		() => lastSeen({ timestamp: last_checked, short: true }),
		[last_checked]
	);

	const history = useHistory();

	const clickHandler =
		!last_checked || !!errorStatus
			? undefined
			: () => history.push(generatePath(PATHS.DATABASES_TABLE_SAMPLE, { sampleId: id }));

	const errorStatusCell = (
		<Tooltip title={DarObjectStatusLabel[errorStatus] || errorStatus}>
			<Icon name="ErrorCircle/Filled" size={16} className={styles.redIcon} />
		</Tooltip>
	);
	const scanningInProgressCell = (
		<Tooltip title="Scanning in progress">
			<Icon name="Clock/Filled" size={16} className={styles.grayIcon} />
		</Tooltip>
	);
	const lastCheckedCell = (
		<Tooltip title={lastSeenValue.date}>
			<div>{lastSeenValue.diff}</div>
		</Tooltip>
	);

	return (
		<GridRow
			className={styles.rowContainer}
			border
			hover={!!clickHandler}
			onClick={clickHandler}
			data-test="database-table-row"
		>
			<GridCell dataTest="database-table-item-offset">
				{schema_name ? `${schema_name}.${name}` : name}
			</GridCell>
			{/* <GridCell dataTest="database-table-item-offset" align="right"> */}
			{/*	{toLocaleString(rows_count)} */}
			{/* </GridCell> */}
			<GridCell dataTest="database-table-item-partition" align="right">
				{toFileSize(size)}
			</GridCell>
			<GridCell dataTest="database-table-item-timestamp">
				<SensitivityChip sensitivity={sensitivity} />
			</GridCell>
			<GridCell dataTest="database-table-item-data_types">
				<PiiTypeList data={data_types} />
			</GridCell>
			<GridCell dataTest="database-table-item-last-checked">
				{errorStatus && errorStatusCell}
				{!errorStatus && !last_checked && scanningInProgressCell}
				{!errorStatus && last_checked && lastCheckedCell}
			</GridCell>
		</GridRow>
	);
}

export { TableRow };
