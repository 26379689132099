import { useStore } from 'effector-react';
import { generatePath } from 'react-router';
import Typo from 'components/typography/Typo';
import {
	externalAssetsSteps,
	internalAssetsSteps,
} from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { AssetDetailJson } from 'models/assets/dto';
import { getAssetNameByType } from 'models/assets/model';
import { gatewaysNamesById } from 'models/gateways/store';
import { PATHS } from 'services/router';
import { getStringDividedByDot } from 'services/strings';
import { getAssetNameByType as getAssetNameByTypeIcon } from 'views/common/AssetNameWithIcon';

type Props = {
	asset: AssetDetailJson;
	tabLabel: string;
};

function AssetHeader({ asset, tabLabel }: Props) {
	const gatewaysNamesStore = useStore(gatewaysNamesById);
	const displayName = getAssetNameByTypeIcon(asset.type, asset.name, 24);
	const { namespace, cluster_id } = asset;
	const parentBreadcrumb = asset.type === 'internal' ? internalAssetsSteps : externalAssetsSteps;
	const steps = [
		...parentBreadcrumb,
		{
			path: generatePath(PATHS.SERVICE_ITEM, {
				id: asset.id,
				tab: 'info',
			}),
			name: getAssetNameByType(asset.type, asset.name),
		},
	];
	const showSensitiveSwitcher = tabLabel === 'Data flows' || tabLabel === 'Endpoints'; // TODO very fragile

	return (
		<Header>
			<Header.Breadcrumbs steps={steps} finalStep={tabLabel} />

			<Header.Title>
				<Typo variant="D/Medium/H400-Page-Title" dataTest="header-title">
					{displayName}
				</Typo>
			</Header.Title>
			<Header.Subtitle>
				{getStringDividedByDot(gatewaysNamesStore[cluster_id], namespace, asset.k8s_types)}
			</Header.Subtitle>

			{showSensitiveSwitcher && <Header.SensitiveSwitcher />}
		</Header>
	);
}

export default AssetHeader;
