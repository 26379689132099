import cn from 'classnames';
import { useEffect, useState } from 'react';
import Preloader from 'components/Preloader';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getRecentActivityFx } from 'models/recentActivity/effects';
import { getReleaseFx } from 'models/release/effects';
import { ReleaseUpdateAlert } from 'views/common/ReleaseUpdateAlert';
import { ApiCallsChart } from './ApiCallsChart';
import DataCombinations from './DataCombinations';
import { DiscoveredDataTypes } from './DiscoveredDataTypes';
import EventSummary from './EventSummary';
import styles from './index.module.pcss';
import TopAssets from './TopAssets';
import TotalNumbers from './TotalNumbers';

function Overview() {
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		Promise.all([getRecentActivityFx(), getReleaseFx()]).then(() => {
			setLoading(false);
		});
	}, []);

	return (
		<>
			<Header>
				<Header.Breadcrumbs hideHome />
				<Header.Title>Dashboard</Header.Title>
			</Header>

			<Preloader isLoading={isLoading}>
				<ReleaseUpdateAlert type="dim" />
				<ReleaseUpdateAlert type="dar" />

				<div className={styles.eventsContainer}>
					<EventSummary />

					<div className={styles.topRightContainer}>
						<TotalNumbers />
					</div>
				</div>

				<div className={styles.widget}>
					<ApiCallsChart />
				</div>

				<div className={styles.widget}>
					<DiscoveredDataTypes />
				</div>

				<div className={styles.widget}>
					<TopAssets />
				</div>

				<div className={cn(styles.widget, styles.lastWidget)}>
					<DataCombinations />
				</div>
			</Preloader>
		</>
	);
}

export default Overview;
