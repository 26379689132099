import { createEvent, createStore } from 'effector';
import { ReactNode } from 'react';

type HeaderStore = {
	isLoading?: boolean;
	children?: ReactNode;
};
const initialState: HeaderStore = {};

const headerStore = createStore<HeaderStore>(initialState);
const setHeader = createEvent<HeaderStore>();
const clearHeader = createEvent<void>();

headerStore.on(setHeader, (_, newState) => newState);
headerStore.on(clearHeader, () => initialState);

export { headerStore, setHeader, clearHeader };
