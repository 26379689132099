import { useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Tooltip from 'components/Tooltip';
import { NoSQLDatabaseSampleItem } from 'models/noSQLDatabases/dto';
import { lastSeen } from 'services/lastSeen';
import { PATHS } from 'services/router';
import styles from './index.module.css';

type Props = {
	sample: NoSQLDatabaseSampleItem;
};

function SampleRow({ sample }: Readonly<Props>) {
	const { id, object_id, last_checked, data_types, sensitivity } = sample;
	const lastSeenValue = useMemo(
		() => lastSeen({ timestamp: last_checked, short: true }),
		[last_checked]
	);

	const history = useHistory();
	function clickHandler() {
		history.push(generatePath(PATHS.NOSQL_DATABASES_SAMPLE, { id }));
	}

	return (
		<GridRow
			className={styles.rowContainer}
			border
			hover
			onClick={clickHandler}
			data-test="nosql-database-sample-row"
		>
			<GridCell dataTest="nosql-database-sample-item-object-id">{object_id}</GridCell>

			<GridCell dataTest="nosql-database-sample-sensitivity">
				<SensitivityChip sensitivity={sensitivity} />
			</GridCell>

			<GridCell dataTest="nosql-database-sample-data-types">
				<PiiTypeList data={data_types} />
			</GridCell>

			<GridCell dataTest="nosql-database-sample-item-last-checked">
				<Tooltip className={styles.lastChecked} title={lastSeenValue.date}>
					{lastSeenValue.diff}
				</Tooltip>
			</GridCell>
		</GridRow>
	);
}

export { SampleRow };
