import { createEffect } from 'effector';
import { getNamespaces } from './api';
import { NamespaceLabels } from './dto';

/* API */

const getNamespacesFx = createEffect<void, NamespaceLabels>();

/* IMPLEMENTATION */

// Simple direct-to-api effects

getNamespacesFx.use(getNamespaces);

export { getNamespacesFx };
