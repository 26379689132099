import cn from 'classnames';
import { useStore } from 'effector-react';
import { useMemo } from 'react';
import { generatePath } from 'react-router';
import Chip from 'components/Chip';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { AssetType } from 'models/assets/dto';
import { assetGroupsList } from 'models/assetsGroups/store';
import { gatewaysNamesById } from 'models/gateways/store';
import { PATHS } from 'services/router';
import { DASH_SIGN } from 'services/strings';
import { MapAsset } from '../../../index';
import commonStyles from '../index.module.css';
import Label from '../Label';
import styles from './index.module.pcss';

const objectTypes: Record<AssetType, string> = {
	internal: 'Service',
	endusers: 'External connection',
	external_namespace: 'Excluded namespace',
	robots: 'External connection',
	other_in: 'External connection',
	other_out: 'External connection',
	custom: 'External connection',
};

type Props = {
	asset: MapAsset;
};

function Summary(props: Props) {
	const { asset } = props;

	const gateways = useStore(gatewaysNamesById);
	const groups = useStore(assetGroupsList);

	const labelsRendered = useMemo(
		function () {
			return asset.labels.map((label) => {
				const asString = `${label.key}:${label.value}`;

				return (
					<Typo
						key={asString}
						variant="D/Medium/Meta"
						className={cn(commonStyles.ellipses, commonStyles.label)}
					>
						{asString}
					</Typo>
				);
			});
		},
		[asset]
	);

	const groupsRendered = useMemo(
		function () {
			return asset.groups.map((groupId) => {
				const group = groups.find((g) => g.id === groupId)!;

				if (!group) return null;

				return (
					<RouterLink key={group.id} to={generatePath(PATHS.ASSET_GROUP_ITEM, { id: group?.id })}>
						<Chip
							theme="assetGroup"
							label={group.name}
							size="small"
							className={styles.groupChip}
							classes={{ label: commonStyles.ellipses }}
						/>
					</RouterLink>
				);
			});
		},
		[asset, groups]
	);

	return (
		<div className={commonStyles.sidePaddings}>
			<div className={commonStyles.detailsGrid}>
				<Label data-test="asset-details-object-type" title="Resource">
					{objectTypes[asset.type as AssetType]}
				</Label>

				<Label data-test="asset-details-cluster" title="Cluster">
					{gateways[asset.cluster_id] || DASH_SIGN}
				</Label>

				{asset.type === 'internal' && (
					<Label title="Namespace">{asset.namespace || DASH_SIGN}</Label>
				)}

				<Label title="Description">{asset.description || DASH_SIGN}</Label>

				<Label title="Owner">{asset.owner || DASH_SIGN}</Label>

				{asset.type === 'internal' && (
					<Label title="Labels">
						<div className={commonStyles.labelList}>
							{labelsRendered.length > 0 ? labelsRendered : DASH_SIGN}
						</div>
					</Label>
				)}

				{asset.type === 'internal' && (
					<Label title="Groups">
						<div className={commonStyles.labelList}>
							{groupsRendered.length > 0 ? groupsRendered : DASH_SIGN}
						</div>
					</Label>
				)}
			</div>

			<Button
				color="tertiary"
				size="small"
				endIcon={<Icon name="Open/Regular" size={20} />}
				fullWidth
				href={generatePath(PATHS.SERVICE_ITEM, { id: asset.entityId, tab: 'info' })}
				target="_blank"
				className={styles.detailedInfo}
			>
				View detailed info
			</Button>
		</div>
	);
}

export default Summary;
