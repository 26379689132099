import { generatePath } from 'react-router';
import { kafkaTopicsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { KafkaTopicSample } from 'models/kafkaTopics/dto';
import { DateFormat, dayjs } from 'services/dayjs';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';

type Props = {
	sample: Pick<
		KafkaTopicSample,
		| 'topic_id'
		| 'topic_name'
		| 'partition'
		| 'offset'
		| 'message_timestamp'
		| 'schema_id'
		| 'schema_name'
	> | null;
};

function TopicSampleHeader({ sample }: Props) {
	if (!sample) return null;

	const { topic_id, topic_name, partition, offset, message_timestamp, schema_id, schema_name } =
		sample;
	const title = `offset: ${toLocaleString(offset)}; partition: ${partition}`;
	const timestamp = dayjs(message_timestamp).format(DateFormat.timestamp);

	const steps = [
		...kafkaTopicsSteps,
		{
			path: generatePath(PATHS.KAFKA_TOPICS_ITEM, {
				id: topic_id,
				tab: 'summary',
			}),
			name: topic_name,
		},
		{
			path: generatePath(PATHS.KAFKA_TOPICS_ITEM, {
				id: topic_id,
				tab: 'message-schemas',
			}),
			name: 'Message schemas',
		},
		{
			path: generatePath(PATHS.KAFKA_MESSAGE_SCHEMA_ITEM, {
				topicId: topic_id,
				schemaId: schema_id,
			}),
			name: schema_name,
		},
	];

	return (
		<Header>
			<Header.Breadcrumbs steps={steps} finalStep={title} />

			<Header.Title>{title}</Header.Title>
			<Header.Subtitle>{timestamp}</Header.Subtitle>
		</Header>
	);
}

export { TopicSampleHeader };
