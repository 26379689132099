import { CountersJson } from './dto';

const countersJsonExample: CountersJson = {
	buckets: 42,
	endpoints: 51,
	external_assets: 1,
	internal_assets: 10,
	kafka_instances: 1,
	kafka_topics: 12,
	no_sql_db_databases: 4,
	sql_db_databases: 8,
};

export { countersJsonExample };
