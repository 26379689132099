import { useState } from 'react';
import { MapAsset } from '../../index';
import { CanvasAsset } from './CanvasAsset';
import { CanvasLabel } from './CanvasLabel';
import styles from './index.module.css';

interface Props {
	element: MapAsset;
	faded: boolean;
	sensitive: boolean;
	selected: boolean;
	onSelect: () => void;
	highlight: string;
	position: {
		top: number;
		left: number;
		width: number;
		height: number;
	};
}

export const CanvasCube = ({
	element,
	faded,
	sensitive,
	selected,
	onSelect,
	highlight,
	position,
}: Props) => {
	const [hovered, setHovered] = useState(false);

	let imageId =
		element.type === 'external_namespace'
			? 'honeycombs'
			: element.type === 's3_bucket'
			? 's3'
			: element.type === 'sql_db_database' || element.type === 'nosql_db_database'
			? 'database'
			: element.type === 'kafka_instance'
			? 'kafka'
			: element.name.includes('vm-test')
			? 'vm'
			: 'asset';
	if (sensitive) {
		switch (element.sensitivity) {
			case 'Low':
			case 'N/A':
				imageId += 'Default';
				break;

			default:
				imageId += element.sensitivity;
		}
	} else {
		imageId += 'Default';
	}
	imageId += selected ? 'Selected' : '';

	if (hovered && !selected) imageId += 'Hovered';

	return (
		<div
			className={styles.canvasWrapper}
			style={{
				top: position.top,
				left: position.left,
				transform: 'scale(0.333333)',

				opacity: faded ? 0.3 : 1,
			}}
			data-test={`data-map-cube-${element.name}`}
		>
			<CanvasLabel
				type={element.type}
				name={element.name}
				selected={selected}
				highlight={highlight}
				onSelect={onSelect}
				imageId={imageId}
			/>

			<CanvasAsset onSelect={onSelect} imageId={imageId} setHovered={setHovered} />
		</div>
	);
};
