import { createStore } from 'effector';
import { AISession, AISessionsList } from './dto';
import { getAISessionsListFx } from './effects';

export const aiSessionStore = createStore<AISession[]>([]);

aiSessionStore.on(
	getAISessionsListFx.doneData,
	(state: AISession[], data: AISessionsList) => data.sessions
);

const aiSessionsList = aiSessionStore.map((sessions) => sessions);

export { aiSessionsList };
