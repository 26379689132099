import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Checkbox from 'components/Checkbox';
import Button from 'components/form/Button';
import TextField from 'components/form/TextField';
import Preloader from 'components/Preloader';
import { SecretToken } from 'components/SecretToken';
import { enqueueSnackbar } from 'components/Snackbar';
import Typo from 'components/typography/Typo';
import { integrationsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getWebhookIntegration, updateWebhookSettings } from 'models/integrations/webhook/api';
import { WebhookNotifyType, WebhookSettings } from 'models/integrations/webhook/dto';
import { APIError } from 'services/api/httpRequest';
import { NeedHelpBlock } from 'views/common/NeedHelpBlock';
import ConnectionStatus from './ConectionStatus';
import styles from './index.module.css';
import WebhookTestStatus from './WebhookTestStatus';

type FormValues = {
	url: string;
	token: string;
	notify: WebhookSettings['notify'];
};

const notifyOptions: { label: string; value: WebhookNotifyType }[] = [
	{
		label: 'All events',
		value: 'all',
	},
	{
		label: 'New data type',
		value: 'new_data_types',
	},
	{
		label: 'Policy violation',
		value: 'policy_violations',
	},
	{
		label: 'Data flow change',
		value: 'data_flow_changes',
	},
	{ label: 'Data storage change', value: 'started_storing' },
	{
		label: 'Other',
		value: 'others',
	},
];

const initialSettings: WebhookSettings = {
	status: 'unknown',
	secret_token: '',
	url: '',
	notify: ['all'],
	is_enabled: false,
};

function Settings() {
	const history = useHistory();
	const [settings, setSettings] = useState<WebhookSettings>(initialSettings);
	const [isLoading, setLoading] = useState(true);
	const [isUpdateLoading, setUpdateLoading] = useState(false);
	const [correctMode, setCorrectMode] = useState(false);

	useEffect(() => {
		getWebhookIntegration()
			.then((setting) => {
				setSettings(setting);
				reset({
					url: setting.url,
					token: setting.secret_token,
					notify: setting.notify,
				});
				setLoading(false);
			})
			.catch(() => {
				enqueueSnackbar('Something went wrong');
			});
	}, []);

	const {
		control,
		handleSubmit,
		reset,
		formState: { isDirty },
	} = useForm<FormValues>({
		defaultValues: {
			url: settings.url,
			token: settings.secret_token,
			notify: settings.notify,
		},
		mode: 'onBlur',
	});

	async function onSubmit({ url, token, notify }: FormValues) {
		setUpdateLoading(true);
		const trimmedUrl = url.trim();
		const trimmedToken = token.trim();
		const preparedNotify: WebhookNotifyType[] = notify.includes('all') ? ['all'] : notify;

		try {
			await updateWebhookSettings({
				url: trimmedUrl,
				secret_token: trimmedToken,
				notify: preparedNotify,
				is_enabled: !!trimmedUrl,
			}).then((setting) => {
				setSettings(setting);
				reset({
					url: setting.url,
					token: setting.secret_token,
					notify: setting.notify,
				});
				enqueueSnackbar('Settings saved');
			});

			setCorrectMode(false);
		} catch (error) {
			if (
				error instanceof APIError &&
				(error.response.status === 401 || error.response.status === 400)
			) {
				const { message } = await error.response.json();
				enqueueSnackbar(message);
			} else {
				throw error;
			}
		} finally {
			setUpdateLoading(false);
		}
	}

	function onCancel() {
		history.goBack();
	}

	const editable = useMemo(() => {
		return Boolean(settings.url || settings.secret_token) && !correctMode;
	}, [settings, correctMode]);

	function onChangeNotify(value: string, checked: boolean, fieldValue: string[]) {
		if (value === 'all') {
			return ['all'];
		} else {
			if (checked) {
				const notifyWithoutAll = fieldValue.filter((field) => field !== 'all');
				const newNotify = [...notifyWithoutAll, value];

				return newNotify.length === notifyOptions.length - 1 ? ['all'] : newNotify;
			} else {
				const newNotify = fieldValue.filter((field) => field !== value && field !== 'all');

				return newNotify.length === 0 ? ['all'] : newNotify;
			}
		}
	}

	return (
		<>
			<Header>
				<Header.Breadcrumbs steps={integrationsSteps} finalStep="Webhook configuration" />

				<Header.Title>
					<div className={styles.header}>
						<div className={styles.title}>
							<Typo variant="D/Medium/H400-Page-Title">Webhook configuration</Typo>

							<ConnectionStatus status={settings.status} />
						</div>
					</div>
				</Header.Title>
			</Header>

			<Preloader isLoading={isLoading}>
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<Typo variant="D/Medium/Body" className={styles.editContainer}>
						Connection settings
						{editable && (
							<Typo
								variant="D/Medium/Body"
								className={styles.editLink}
								onClick={() => setCorrectMode(true)}
								data-test="webhook-settings-edit"
								component="span"
							>
								Edit
							</Typo>
						)}
					</Typo>

					<div className={styles.inputs}>
						<TextField
							required
							type="url"
							label="Webhook URL"
							name="url"
							fullWidth
							control={control}
							readOnly={editable}
							data-test="webhook-settings-url"
						/>

						{editable ? (
							<SecretToken
								label="Token"
								name="token"
								fullWidth
								control={control}
								data-test="webhook-settings-token"
							/>
						) : (
							<TextField
								label="Token"
								name="token"
								fullWidth
								control={control}
								data-test="webhook-settings-token"
							/>
						)}
					</div>

					<Typo variant="D/Medium/Body">
						What event categories do you want to get notifications about?
					</Typo>

					<div className={styles.checkboxContainer}>
						<Controller
							name="notify"
							control={control}
							render={({ field }) => (
								<>
									{notifyOptions.map((notifyOption) => (
										<Checkbox
											size="M"
											key={notifyOption.value}
											label={notifyOption.label}
											dataTest={`webhook-settings-dynamic-${notifyOption.value}`}
											checked={field.value.includes(notifyOption.value)}
											value={notifyOption.value}
											onChange={({ target }) => {
												field.onChange(onChangeNotify(target.value, target.checked, field.value));
											}}
										/>
									))}
								</>
							)}
						/>
					</div>

					<NeedHelpBlock />

					<div className={styles.buttons}>
						<Button color="secondary" onClick={onCancel} data-test="webhook-settings-cancel">
							Cancel
						</Button>

						<Button
							type="submit"
							loading={isUpdateLoading}
							data-test="webhook-settings-save"
							disabled={!isDirty}
						>
							Save
						</Button>
					</div>
				</form>

				<WebhookTestStatus correctMode={correctMode} />
			</Preloader>
		</>
	);
}

export default Settings;
