import {
	NoSQLDatabaseCollectionItem,
	NoSQLDatabaseCollectionList,
	NoSQLDatabaseInstanceItem,
	NoSQLDatabaseInstanceList,
	NoSQLDatabaseItem,
	NoSQLDatabaseList,
	NoSQLDatabaseDetails,
	NoSQLDatabaseCollectionDetails,
	NoSQLDatabaseSchemaItem,
	NoSQLDatabaseSchemasList,
	NoSQLDatabaseSchemaDetails,
	NoSQLDatabaseSampleItem,
	NoSQLDatabaseSamplesList,
	NoSQLDatabaseSampleDetails,
} from './dto';

/* Instances */
const noSQLDatabaseInstanceItemExample: NoSQLDatabaseInstanceItem = {
	data_types: [1, 2],
	databases_count: 2,
	has_tls: true,
	id: 5,
	is_crawler_attached: true,
	last_checked: 1638267989332,
	name: 'Instance #5',
	sensitivity: 'High',
	size: 697451079,
	status: '',
	type: 'mongodb',
};

const noSQLDatabaseInstanceListExample: NoSQLDatabaseInstanceList = {
	instances: [noSQLDatabaseInstanceItemExample],
	total: 1,
};

/* Databases */
const noSQLDatabaseItemExample: NoSQLDatabaseItem = {
	collections_count: 38,
	data_types: [3, 4],
	id: 15,
	instance_id: 5,
	last_checked: 1638267989332,
	name: 'Database #15',
	sensitivity: 'High',
	size: 697451079,
	status: 'unsupported_format',
};

const noSQLDatabaseListExample: NoSQLDatabaseList = {
	databases: [noSQLDatabaseItemExample],
	total: 1,
	total_filtered: 1,
};

const noSQLDatabaseDetailsExample: NoSQLDatabaseDetails = {
	...noSQLDatabaseItemExample,
	description: 'Database description',
	owner: 'Database owner',
};

/* Collections */
const noSQLDatabaseCollectionItemExample: NoSQLDatabaseCollectionItem = {
	data_types: [3, 4],
	id: 115,
	last_checked: 1638267989332,
	name: 'Collection #115',
	sensitivity: 'High',
	size: 697451079,
	status: '',
};

const noSQLDatabaseCollectionListExample: NoSQLDatabaseCollectionList = {
	collections: [noSQLDatabaseCollectionItemExample],
	total: 1,
	total_filtered: 1,
};

const noSQLDatabaseCollectionDetailsExample: NoSQLDatabaseCollectionDetails = {
	...noSQLDatabaseCollectionItemExample,
	database_id: 15,
	database_name: 'Database #15',
	description: 'Collection description',
	instance_id: 5,
	owner: 'Collection owner',
	schemas_count: 12,
};

/* Schemas */
const noSQLDatabaseSchemaItemExample: NoSQLDatabaseSchemaItem = {
	data_types: [3, 4],
	id: 1115,
	last_checked: 1638267989332,
	name: 'Schema #1115',
	sensitivity: 'High',
	type: 'json',
};

const noSQLDatabaseSchemaListExample: NoSQLDatabaseSchemasList = {
	schemas: [noSQLDatabaseSchemaItemExample],
	total: 1,
	total_filtered: 1,
};

const noSQLDatabaseSchemaDetailsExample: NoSQLDatabaseSchemaDetails = {
	...noSQLDatabaseSchemaItemExample,
	collection_id: 115,
	collection_name: 'Collection #115',
	database_id: 15,
	database_name: 'Database #15',
	instance_id: 5,
};

/* Samples */
const noSQLDatabaseSampleItemExample: NoSQLDatabaseSampleItem = {
	data_types: [3, 4],
	id: 11115,
	last_checked: 1638267989332,
	object_id: 'Sample #11115',
	sensitivity: 'High',
};

const noSQLDatabaseSampleListExample: NoSQLDatabaseSamplesList = {
	samples: [noSQLDatabaseSampleItemExample],
	total: 1,
};

const noSQLDatabaseSampleDetailsExample: NoSQLDatabaseSampleDetails = {
	collection_id: 115,
	collection_name: 'Collection #115',
	database_id: 15,
	database_name: 'Database #15',
	id: 11115,
	instance_id: 5,
	last_checked: 1714396108546,
	object_id: 'Sample #11115',
	samples: [
		{
			name: '',
			sample: '{ "test": "some string" }',
			data_fields: [
				{
					locator_path: '$.test',
					locator_column: 0,
					locator_line: 0,
					data_type: 12,
				},
			],
		},
	],
	schema_id: 1115,
	schema_name: 'Schema #1115',
	type: 'json',
};

export {
	noSQLDatabaseInstanceListExample,
	noSQLDatabaseListExample,
	noSQLDatabaseDetailsExample,
	noSQLDatabaseCollectionListExample,
	noSQLDatabaseCollectionDetailsExample,
	noSQLDatabaseSchemaListExample,
	noSQLDatabaseSchemaDetailsExample,
	noSQLDatabaseSampleListExample,
	noSQLDatabaseSampleDetailsExample,
};
