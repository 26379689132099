import { createStore } from 'effector';
import { CountersJson } from './dto';
import { getCountersFx } from './effects';

const $counters = createStore<CountersJson>({
	buckets: 0,
	endpoints: 0,
	external_assets: 0,
	internal_assets: 0,
	kafka_instances: 0,
	kafka_topics: 0,
	no_sql_db_databases: 0,
	sql_db_databases: 0,
});

$counters.on(getCountersFx.doneData, (state: CountersJson, counters?: CountersJson) => {
	return { ...state, ...counters };
});

export { $counters };
