import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Preloader from 'components/Preloader';
import Typo from 'components/typography/Typo';
import { kafkaTopicsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getKafkaTopicSample } from 'models/kafkaTopics/api';
import { KafkaTopicSample } from 'models/kafkaTopics/dto';
import { JSONViewer } from 'views/common/SampleViewer/JSONViewer';
import { LogViewer } from 'views/common/SampleViewer/LogViewer';
import { YAMLViewer } from 'views/common/SampleViewer/YAMLViewer';
import styles from './index.module.css';
import { TopicSampleHeader } from './TopicSampleHeader';

function TopicSampleError({ sampleId }: { sampleId: string }) {
	return (
		<>
			<Header>
				<Header.Breadcrumbs steps={kafkaTopicsSteps} finalStep="Not found" />

				<Header.Title>Error has occurred while loading kafka topic info</Header.Title>
			</Header>

			<Typo variant="D/Regular/Body-S" color="secondary">
				Kafka topic sample with id={sampleId} not found or was deleted.
			</Typo>
		</>
	);
}

function TopicSampleViewers() {
	const [sample, setSample] = useState<KafkaTopicSample>();
	const [isLoading, setLoading] = useState(true);

	const params: {
		sampleId: string;
	} = useParams();

	useEffect(() => {
		getKafkaTopicSample(+params.sampleId).then((response) => {
			setSample(response);
			setLoading(false);
		});
	}, []);

	const Viewer = useMemo(() => {
		if (!sample || !sample.samples) {
			return null;
		}

		switch (sample?.type) {
			// We never find here CSV files because they have their own API and Viewer
			case 'log':
				return <LogViewer {...sample} {...sample?.samples[0]} />;
			case 'json':
				return <JSONViewer {...sample} {...sample?.samples[0]} />;
			case 'yaml':
				return <YAMLViewer {...sample} {...sample?.samples[0]} />;
			default:
				return null;
		}
	}, [sample]);

	return (
		<Preloader isLoading={isLoading}>
			{sample ? (
				<>
					<TopicSampleHeader sample={sample} />

					<div className={styles.wrapper}>{Viewer}</div>
				</>
			) : (
				<TopicSampleError sampleId={params.sampleId} />
			)}
		</Preloader>
	);
}

export { TopicSampleViewers };
