import { MouseEvent, useState } from 'react';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import { FilterType } from '../model';
import styles from './index.module.pcss';

const filterLabels: { [name in FilterType]: string } = {
	assets: 'Service',
	clusters: 'Cluster',
	clustersInternal: 'Cluster',
	clustersExternal: 'Cluster',
	groups: 'Group',
	dataTypes: 'Data types',
	eventType: 'Event type',
	eventStart: 'Last 90 days',
	namespaces: 'Namespace',
	namespacesEndpoints: 'Namespace',
	regions: 'Region',
	labelKeys: 'Label key',
	labelValues: 'Label value',
	namespaceLabelKeys: 'Namespace label key',
	namespaceLabelValues: 'Namespace label value',
	kafkaInstances: 'Cluster',
	protocol: 'Protocol',
};

type Props<T extends FilterType> = {
	list: T[];
	onChange: (type: T) => void;
};

function AddFilterMenu<T extends FilterType>(props: Props<T>) {
	const { list, onChange } = props;

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	if (list.length === 0) return null;

	const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				data-test="add-filter-button"
				startIcon={<Icon name="Add/Filled" size={20} />}
				size="extraSmall"
				color="tertiary"
				onClick={handleOpen}
			>
				Add filter
			</Button>

			<Menu
				className={styles.menuContainer}
				anchorEl={anchorEl}
				open={!!anchorEl}
				onClose={handleClose}
				data-test="add-filter-menu"
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				{list.map((item) => (
					<MenuItem
						data-test="add-filter-item"
						size="small"
						onClick={() => {
							onChange(item);
							handleClose();
						}}
						key={item}
					>
						{filterLabels[item]}
					</MenuItem>
				))}
			</Menu>
		</>
	);
}

export default AddFilterMenu;
