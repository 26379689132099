import { IHeadCell } from 'components/table/EnhancedTableHead';
import { NoSQLDatabaseSampleItem } from 'models/noSQLDatabases/dto';

const tableConfig: IHeadCell<NoSQLDatabaseSampleItem>[] = [
	{
		id: 'object_id',
		numeric: false,
		label: 'Object Id',
		sortable: true,
	},
	{
		id: 'sensitivity',
		numeric: false,
		label: 'Sensitivity',
		sortable: true,
	},
	{
		id: 'data_types',
		numeric: false,
		label: 'Data types',
		sortable: false,
	},
	{
		id: 'last_checked',
		numeric: false,
		label: 'Last checked',
		sortable: false,
	},
];

export default tableConfig;
