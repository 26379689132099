import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import MultiSelect from 'components/form/MultiSelect';
import DropdownButton from 'components/form/Select/DropdownButton';
import { NamespaceLabel } from 'models/namespaces/dto';
import { getNamespacesFx } from 'models/namespaces/effects';
import { namespaceLabelsList } from 'models/namespaces/store';
import { FilterPropsBase } from '../model';

type LabelKey = NamespaceLabel['key'];

type Option = { id: LabelKey; name: LabelKey };

type Props = FilterPropsBase & {
	value: LabelKey[];
	onChange: (newValue: LabelKey[]) => void;
};

function NamespaceLabelKeyFilter({
	defaultOpen,
	fixed,
	onChange,
	onClose,
	resetFilter,
	value,
}: Props) {
	const namespaceLabels = useStore(namespaceLabelsList);

	useEffect(() => {
		getNamespacesFx();
	}, []);

	const options = useMemo(() => {
		return namespaceLabels.map((label) => ({
			id: label.key,
			name: label.key,
		}));
	}, [namespaceLabels]);

	const selected = useMemo(() => {
		return options.filter((val) => value.includes(val.name));
	}, [value, options]);

	function handleChange(newSelected: Option[]) {
		onChange(newSelected.map((option) => option.name));
	}

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<MultiSelect
			defaultOpen={defaultOpen}
			label={{ primary: 'Namespace label keys', secondary: `${selected.length || ''}` }}
			options={options}
			value={selected}
			onChange={handleChange}
			onClose={onClose}
			render={{ dropdownButton }}
			hasSearch
			hasApplyButton
		/>
	);
}

export default NamespaceLabelKeyFilter;
