import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams, Link } from 'react-router-dom';
import Badge from 'components/Badge';
import { piiFilterStore } from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import { enqueueSnackbar } from 'components/Snackbar';
import { TabLink, TabPanel, Tabs } from 'components/Tabs';
import Typo from 'components/typography/Typo';
import { groupsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getAssetGroupCounters } from 'models/assetsGroups/api';
import { AssetGroup, AssetGroupCounters, AssetGroupListItem } from 'models/assetsGroups/dto';
import { getAssetGroupFx, deleteAssetGroupFx } from 'models/assetsGroups/effects';
import { assetGroupsList } from 'models/assetsGroups/store';
import { APIError } from 'services/api/httpRequest';
import { goBackByDefault } from 'services/history';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import AssetGroupForm from 'views/AssetGroups/AssetGroupForm';
import { EMPTY_RULE } from 'views/Policy/PolicyItem/PolicyItemForm/PolicyRules';
import InternalAssets from 'views/SuperAssets/InternalAssets';
import DataFlowTable from '../DataFlowTable';
import { DeleteModal } from './DeleteModal';

const duplicateGroupPath = generatePath(PATHS.ASSET_GROUP_ITEM, { id: 'new' });
const LS_ASSET_GROUP_DUPLICATE_KEY = 'asset-group-duplicate';

const EMPTY_GROUP: AssetGroup = {
	id: 0,
	name: '',
	description: '',
	owner: '',
	rules: [[EMPTY_RULE]],
	created_at: 0,
	updated_at: 0,
};

type AssetGroupItemProps = {
	assetGroup: AssetGroup;
	counters: AssetGroupCounters;
};

const tabLabels = {
	info: 'Summary',
	'data-flows': 'Data flows',
	assets: 'Services',
};

function AssetGroupItem(props: AssetGroupItemProps) {
	const { assetGroup, counters } = props;

	const history = useHistory();
	const params: {
		tab?: 'info' | 'data-flows' | 'assets';
	} = useParams();

	const tabLinks = useMemo(
		() => ({
			info: generatePath(PATHS.ASSET_GROUP_ITEM, {
				id: assetGroup.id,
				tab: 'info',
			}),
			'data-flows': generatePath(PATHS.ASSET_GROUP_ITEM, {
				id: assetGroup.id,
				tab: 'data-flows',
			}),
			assets: generatePath(PATHS.ASSET_GROUP_ITEM, {
				id: assetGroup.id,
				tab: 'assets',
			}),
		}),
		[assetGroup.id]
	);

	// Set default tab, if not specified.
	useEffect(function () {
		if (!params.tab) {
			const path = generatePath(PATHS.ASSET_GROUP_ITEM, {
				...params,
				tab: 'info',
			});
			history.replace(path + history.location.search);
		}
	}, []);

	return (
		<>
			<Tabs value={tabLinks[params.tab || 'info']}>
				<TabLink value={tabLinks['info']} replace label="Summary" />
				<TabLink
					value={tabLinks['data-flows']}
					replace
					label={
						<>
							Data flows
							<Badge badgeContent={toLocaleString(counters.dataflows)} />
						</>
					}
				/>
				<TabLink
					value={tabLinks['assets']}
					replace
					label={
						<>
							Services
							<Badge badgeContent={toLocaleString(counters.assets)} />
						</>
					}
				/>
			</Tabs>

			<TabPanel value={params.tab} index="info">
				<AssetGroupForm data={assetGroup} />
			</TabPanel>

			<TabPanel value={params.tab} index="data-flows">
				<DataFlowTable groupId={assetGroup.id} />
			</TabPanel>

			<TabPanel value={params.tab} index="assets">
				<InternalAssets group={assetGroup.id} totalByGroup={true} />
			</TabPanel>
		</>
	);
}

const initialCounters: AssetGroupCounters = { assets: 0, dataflows: 0 };

function AssetGroupItemLoader() {
	const groups = useStore(assetGroupsList);
	const { nonEmpty } = useStore(piiFilterStore);
	const history = useHistory();

	const [group, setGroup] = useState<AssetGroup>(EMPTY_GROUP);
	const [newGroupTemplate, setNewGroupTemplate] = useState<AssetGroup>({ ...EMPTY_GROUP });
	const [groupState, setGroupState] = useState<'loading' | 'new' | 'edit' | 'notExist'>('loading');
	const [counters, setCounters] = useState<AssetGroupCounters>(initialCounters);

	const params: {
		id: string;
		tab?: 'info' | 'data-flows' | 'assets';
	} = useParams();

	const duplicatedGroupName = (items: AssetGroupListItem[], name: string) => {
		const regex = /Copy( \((\d+)\))?$/;
		if (regex.test(name)) {
			return name.replace(regex, function (match, p1, p2 = '1') {
				const newNumber = Number(p2) + 1;
				return `Copy (${newNumber})`;
			});
		} else {
			return `${name} Copy`;
		}
	};

	useEffect(() => {
		if (params.id === 'new') {
			const duplicatedData = localStorage.getItem(LS_ASSET_GROUP_DUPLICATE_KEY);
			if (duplicatedData) {
				localStorage.removeItem(LS_ASSET_GROUP_DUPLICATE_KEY);

				try {
					const duplicatedGroup = JSON.parse(duplicatedData as string);
					setNewGroupTemplate({
						...duplicatedGroup,
						id: 0,
						name: duplicatedGroupName(groups, duplicatedGroup.name),
					});
				} catch (e) {
					console.warn(`empty ${LS_ASSET_GROUP_DUPLICATE_KEY}`);
				}
			}

			setGroupState('new');
			return;
		}

		const groupIdNumber = Number(params.id);

		if (Number.isNaN(groupIdNumber)) {
			setGroupState('notExist');
			return;
		}

		Promise.all([getAssetGroupFx(groupIdNumber), getAssetGroupCounters(groupIdNumber, nonEmpty)])
			.then(([groupResp, countersResp]) => {
				setGroup(groupResp);
				setCounters(countersResp);
				setGroupState('edit');
			})
			.catch((error) => {
				if (error instanceof APIError && error.response.status === 404) {
					setGroupState('notExist');
				} else throw error;
			});
	}, [params.id]);

	const breadcrumbProps =
		groupState === 'new'
			? { steps: groupsSteps, finalStep: 'New group' }
			: {
					steps: [
						...groupsSteps,
						{
							path:
								generatePath(PATHS.ASSET_GROUP_ITEM, { id: group.id, tab: 'info' }) +
								history.location.search,
							name: group.name,
						},
					],
					finalStep: tabLabels[params.tab || 'info'],
			  };

	const duplicateGroup = useCallback(() => {
		localStorage.setItem(LS_ASSET_GROUP_DUPLICATE_KEY, JSON.stringify(group));
	}, [group]);

	const deleteGroup = async () => {
		const confirmed = await DeleteModal({
			groupName: group.name,
		});

		if (!confirmed) {
			return;
		}

		try {
			await deleteAssetGroupFx(group.id);
			enqueueSnackbar('Group has been deleted');

			goBackByDefault(PATHS.ASSET_GROUP_LIST);
		} catch (error) {
			if (error instanceof APIError) {
				const { message } = await error.response.json();

				enqueueSnackbar(message);
			}
		}
	};

	return (
		<>
			<Header isLoading={groupState === 'loading'}>
				<Header.Breadcrumbs {...breadcrumbProps} />

				<Header.Title>{groupState === 'edit' ? group.name : 'Create group'}</Header.Title>

				{groupState === 'edit' && (
					<Header.CustomAction>
						<Link target="_blank" rel="noopener" to={{ pathname: duplicateGroupPath }}>
							<Header.IconButton
								name="Copy/Regular"
								onClick={duplicateGroup}
								data-test="group-form-duplicate-button"
							>
								Duplicate
							</Header.IconButton>
						</Link>
					</Header.CustomAction>
				)}

				{groupState === 'edit' && (
					<Header.IconButton
						name="Delete/Regular"
						theme="danger"
						onClick={deleteGroup}
						data-test="group-form-delete-button"
					>
						Delete
					</Header.IconButton>
				)}

				{groupState === 'edit' && (params.tab === 'assets' || params.tab === 'data-flows') && (
					<Header.SensitiveSwitcher />
				)}
			</Header>

			<Preloader isLoading={groupState === 'loading'}>
				{groupState === 'new' && <AssetGroupForm data={newGroupTemplate} />}
				{groupState === 'edit' && <AssetGroupItem assetGroup={group} counters={counters} />}
				{groupState === 'notExist' && <AssetGroupNotFound />}
			</Preloader>
		</>
	);
}

function AssetGroupNotFound() {
	const params: {
		id: string;
	} = useParams();

	return (
		<>
			<Header>
				<Header.Breadcrumbs steps={groupsSteps} finalStep="Not found" />

				<Header.Title>Error has occurred while loading service group info</Header.Title>
			</Header>

			<Typo variant="D/Regular/Body-S" color="secondary">
				Asset group with id={params.id} not found or was deleted
			</Typo>
		</>
	);
}

export default AssetGroupItemLoader;
