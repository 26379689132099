import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import AssetChip from 'components/AssetChip';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { endpointsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { AssetJson, AssetsJson } from 'models/assets/dto';
import { getAssetsFx } from 'models/assets/effects';
import { methodOrProtocol } from 'models/endpointsV2/dto';
import { gatewaysNamesById } from 'models/gateways/store';
import { SampleJson } from 'models/samplesV2/dto';
import { lastSeen } from 'services/lastSeen';
import { PATHS } from 'services/router';
import { dividerDot, getStringDividedByDot } from 'services/strings';
import { getAssetNameByType } from 'views/common/AssetNameWithIcon';
import { Direction } from '../';
import styles from './index.module.css';

export interface TitleBlockProps {
	asset: (AssetJson & { cluster_name?: string }) | null;
	sample: SampleJson;
}

function TitleBlocks({ asset, sample }: TitleBlockProps) {
	const gatewaysNamesStore = useStore(gatewaysNamesById);
	const lastSeenValue = useMemo(
		() => lastSeen({ timestamp: sample.last_seen }),
		[sample.last_seen]
	);

	if (asset === null) return ['Error has occurred while loading service info', ''];

	const clusterName = gatewaysNamesStore[asset.cluster_id] || asset.cluster_name;

	return [
		<div className={styles.title} key="1">
			<Typo variant="D/Medium/H100-Header" color="secondary">
				{methodOrProtocol(sample)}
			</Typo>
			<Typo
				variant="D/Medium/H400-Page-Title"
				className={styles.overflow}
				dataTest="endpoint-header-title"
			>
				{sample.host}
				{sample.url}
			</Typo>
		</div>,

		<div className={styles.subheader} data-test="endpoint-header-info" key="2">
			{sample.is_encrypted && <Icon name="tlsRegular" dataTest="tls-icon" />}
			{sample.is_mesh_network && <Icon name="MeshNetwork/Regular" dataTest="mesh-network-icon" />}
			{sample.process_name && (
				<Typo variant="D/Regular/Body-S" color="secondary">
					Process: {sample.process_name}
				</Typo>
			)}

			<AssetChip
				label={getAssetNameByType(asset.type, asset.name, 12)}
				isExternal={asset.is_external}
				size="extraSmall"
				noTooltip
			/>

			<div className={styles.flex}>
				<Typo variant="D/Regular/Body-S" color="secondary" className={styles.flex}>
					{getStringDividedByDot(clusterName, asset.namespace)}
				</Typo>

				{sample.last_seen && (
					<Tooltip title={lastSeenValue.date}>
						<Typo variant="D/Regular/Body-S" color="secondary">
							{dividerDot}Last seen {lastSeenValue.diff}
						</Typo>
					</Tooltip>
				)}
			</div>
		</div>,
	];
}

function SampleHeader({
	sample,
	direction,
	id,
}: {
	sample: SampleJson;
	direction: Direction | string;
	id: string;
}) {
	const assetId = sample.asset;
	const [isLoading, setLoading] = useState(true);
	const [asset, setAsset] = useState<AssetJson | null>(null);

	useEffect(() => {
		if (assetId === 0) return;

		setLoading(true);

		getAssetsFx().then((assetsPayload: AssetsJson) => {
			const found = assetsPayload.assets.find((a) => a.id === assetId) || null;

			setAsset(found);
			setLoading(false);
		});
	}, [assetId]);

	const steps = [
		...endpointsSteps,
		{
			path: `${PATHS.SAMPLE_V2}?id=${id}&direction=request`,
			name: sample.host + sample.url,
		},
	];

	const [title, subtitle] = TitleBlocks({ asset, sample });

	return (
		<Header isLoading={isLoading}>
			<Header.Breadcrumbs steps={steps} finalStep={direction} />

			<Header.Title>{title}</Header.Title>
			{subtitle && <Header.Subtitle>{subtitle}</Header.Subtitle>}
		</Header>
	);
}

export { TitleBlocks };

export default SampleHeader;
