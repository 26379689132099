import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import Label from 'components/Label';
import Switch from 'components/Switch';
import { policiesSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { PATHS } from 'services/router';

const duplicatePolicyPath = generatePath(PATHS.POLICY_ITEM, { id: 'new' });

const PolicyFormHeader = ({
	isNew,
	deletePolicy,
	duplicatePolicy,
	tracking,
	switchTracking,
	name,
}: {
	isNew: boolean;
	deletePolicy: () => void;
	duplicatePolicy: () => void;
	tracking: boolean;
	switchTracking: () => void;
	name: string;
}) => {
	return (
		<Header>
			<Header.Breadcrumbs steps={policiesSteps} finalStep={isNew ? 'New policy' : name} />

			<Header.Title>Policy {isNew ? 'creator' : 'editor'}</Header.Title>

			{!isNew && (
				<Header.CustomAction>
					<Link target="_blank" rel="noopener" to={{ pathname: duplicatePolicyPath }}>
						<Header.IconButton
							name="Copy/Regular"
							onClick={duplicatePolicy}
							data-test="policy-form-duplicate-button"
						>
							Duplicate
						</Header.IconButton>
					</Link>
				</Header.CustomAction>
			)}

			{!isNew && (
				<Header.IconButton
					name="Delete/Regular"
					theme="danger"
					onClick={deletePolicy}
					data-test="policy-form-delete-button"
				>
					Delete
				</Header.IconButton>
			)}

			{!isNew && (
				<Header.CustomAction>
					<Label dataTest="policy-form-violation-tracking">
						<Switch checked={tracking} onChange={switchTracking} />
						Violation tracking
					</Label>
				</Header.CustomAction>
			)}
		</Header>
	);
};

export { PolicyFormHeader };
