import Typo from 'components/typography/Typo';
import { databasesSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getStringDividedByDot } from 'services/strings';
import styles from './index.module.css';

interface WashedSampleProps {
	state: {
		instance_name: string;
		database_name: string;
		collection_name: string;
		type: string;
	} | null;
	id: string;
}

function WashedSample({ id, state }: Readonly<WashedSampleProps>) {
	return (
		<>
			<Header>
				<Header.Breadcrumbs steps={databasesSteps} finalStep="Sample" />

				<Header.Title>
					{state ? state.collection_name : `Database sample with ID ${id}`}
				</Header.Title>
				{state && (
					<Header.Subtitle>
						{getStringDividedByDot(state.instance_name, state.database_name, state.type)}
					</Header.Subtitle>
				)}
			</Header>

			<Typo variant="D/Regular/Body-S" color="secondary" className={styles.noSample}>
				No data available, as the table have not been found during the last scan.
			</Typo>
		</>
	);
}

export type { WashedSampleProps };
export { WashedSample };
