import { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import Preloader from 'components/Preloader';
import { noSQLDatabasesSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getNoSQLDatabaseMessageSchema } from 'models/noSQLDatabases/api';
import { NoSQLDatabaseSchemaDetails } from 'models/noSQLDatabases/dto';
import { PATHS } from 'services/router';
import styles from './index.module.css';
import { SamplesTable } from './SamplesTable';
import { Summary } from './Summary';
import { WashedSchemaProps, WashedSchema } from './WashedSchema';

type Props = {
	schema: NoSQLDatabaseSchemaDetails;
};

function NoSQLSchemaItem({ schema }: Readonly<Props>) {
	const steps = [
		...noSQLDatabasesSteps,
		{
			path: generatePath(PATHS.NOSQL_DATABASES_ITEM, {
				id: schema.database_id,
				tab: 'summary',
			}),
			name: schema.database_name,
		},
		{
			path: generatePath(PATHS.NOSQL_DATABASES_COLLECTION_ITEM, {
				id: schema.collection_id,
				tab: 'summary',
			}),
			name: schema.collection_name,
		},
	];

	return (
		<>
			<Header>
				<Header.Breadcrumbs steps={steps} finalStep={schema.name} />

				<Header.Title>{schema.name}</Header.Title>

				<Header.SensitiveSwitcher />
			</Header>

			<div className={styles.container}>
				<Summary schema={schema} />

				<SamplesTable schemaId={schema.id} />
			</div>
		</>
	);
}

function MessageSchemaItemWrapper() {
	const history = useHistory();
	const { id }: { id: string } = useParams();
	const [loading, setLoading] = useState(true);
	const [schema, setSchema] = useState<NoSQLDatabaseSchemaDetails | undefined>();

	useEffect(() => {
		setLoading(true);

		getNoSQLDatabaseMessageSchema(Number(id))
			.then((data) => {
				setSchema(data);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [id]);

	return (
		<Preloader isLoading={loading}>
			{schema ? (
				<NoSQLSchemaItem schema={schema} />
			) : (
				<WashedSchema id={id} state={history.location.state as WashedSchemaProps['state']} />
			)}
		</Preloader>
	);
}

export { MessageSchemaItemWrapper as NoSQLSchemaItem };
