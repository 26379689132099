import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import { CheckboxBase } from 'components/Checkbox';
import MultiSelect from 'components/form/MultiSelect';
import multiSelectStyles from 'components/form/MultiSelect/index.module.css';
import { OptionItemProps } from 'components/form/MultiSelect/OptionItem';
import DropdownButton from 'components/form/Select/DropdownButton';
import Icon from 'components/Icon';
import MenuItem from 'components/MenuItem';
import { NamespaceLabel } from 'models/namespaces/dto';
import { getNamespacesFx } from 'models/namespaces/effects';
import { namespaceLabelsList } from 'models/namespaces/store';
import { FilterPropsBase, FilterValues } from '../model';
import styles from './index.module.css';

type LabelValue = NamespaceLabel['value'];

type Option = { id: LabelValue; name: LabelValue; disabled: boolean };

type Props = FilterPropsBase & {
	value: LabelValue[];
	onChange: (newValue: LabelValue[]) => void;
	values: Partial<FilterValues>;
};

function NamespaceLabelValueFilter({
	defaultOpen,
	fixed,
	onChange,
	onClose,
	resetFilter,
	value,
	values,
}: Props) {
	const namespaceLabels = useStore(namespaceLabelsList);

	useEffect(() => {
		getNamespacesFx();
	}, []);

	const labelKeys = values?.namespaceLabelKeys || [];

	const options = useMemo(() => {
		const result: {
			[key: string]: Option;
		} = {};

		for (const label of namespaceLabels) {
			const disabled = labelKeys.length > 0 && !labelKeys.includes(label.key);

			// There's a corner case for flipping disabled flag, EVEN if we have processed that label value before:
			//		labelKeys = ['surname'];
			//		namespaceLabels = [{ key: 'lastname', values: ['Smith'] }, { key: 'surname', values: ['Smith'}]]
			for (const val of label.values) {
				result[val] = result[val] || {
					id: val,
					name: val,
					disabled,
				};
			}
		}

		return Object.values(result)
			.filter((option) => !option.disabled || value.includes(option.id)) // Must show previously checked value, even if disabled.
			.sort((a, b) => a.name.localeCompare(b.name)) // This line should remain, despite sorting in store: [key: x, values[b]; key:y, values: [a]]
			.sort((a, b) => Number(a.disabled) - Number(b.disabled));
	}, [namespaceLabels, labelKeys]);

	const selected = useMemo(() => {
		return options.filter((val) => value.includes(val.name));
	}, [value, options]);

	function handleChange(newSelected: Option[]) {
		onChange(newSelected.map((option) => option.name));
	}

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<>
			{labelKeys.length > 0 && <Icon name="Link/Regular" size={20} className={styles.linkIcon} />}

			<MultiSelect
				defaultOpen={defaultOpen}
				label={{ primary: 'Namespace label values', secondary: `${selected.length || ''}` }}
				options={options}
				value={selected}
				onChange={handleChange}
				onClose={onClose}
				render={{ dropdownButton, optionItem: OptionItem }}
				hasSearch
				hasApplyButton
			/>
		</>
	);
}

function OptionItem(props: OptionItemProps<Option>) {
	const { option, selected, searchString, onClick } = props;

	const optionText = option.name;

	const isSearched = searchString
		? optionText.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
		: true;

	return isSearched ? (
		<MenuItem size="small" onClick={onClick} disabled={!!option.disabled}>
			<CheckboxBase checked={selected} className={multiSelectStyles.checkbox} />
			{optionText}
		</MenuItem>
	) : null;
}

export default NamespaceLabelValueFilter;
