import { useStore } from 'effector-react';
import { useEffect } from 'react';
import FilterLine from 'components/FilterLine';
import FilterResult from 'components/FilterResult';
import LoadMoreButton from 'components/LoadMoreButton';
import NewGatewayBlock from 'components/NewGatewayBlock';
import NoContent from 'components/NoContent';
import {
	clearPiiFilter,
	piiFilterToArray,
	piiFilterWithGroupsStore,
} from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import ResetFilters from 'components/ResetFilters';
import EnhancedTableHead, { TOrder } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import { dataCatalogSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { TAssetsItem } from 'models/assetsExtended/dto';
import { externalAssetsModel } from 'models/assetsExtended/model';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import { PATHS } from 'services/router';
import { UniversalSearch } from 'views/common/UniversalSearch';
import { SuperAssets } from '../index';
import tableConfig from './config';
import { ExternalAssetTableRow } from './ExternalAssetTableRow';
import styles from './index.module.css';

const pageConfig = {
	sort: {
		type: 'sort',
		persistence: 'session',
	},
	name: {
		type: 'string',
		persistence: 'session',
	},
	'cluster-ids': {
		type: 'numberArray',
		persistence: 'session',
	},
	search: {
		type: 'string',
		persistence: 'session',
	},
	'data-types': {
		type: 'numberArray',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	nonempty: {
		type: 'boolean',
		persistence: 'session', // TODO implement 'global' in actuality
	},
} satisfies PageParamsConfig;

function ExternalAssets() {
	const state = useStore(externalAssetsModel.store);
	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);

	const [pageParams, setPageParams] = usePageParams(pageConfig);

	useEffect(() => {
		setPageParams({
			...pageParams,
			'data-types': piiFilterWithGroups.dataTypes,
			nonempty: piiFilterWithGroups.nonEmpty,
		});
	}, [piiFilterWithGroups]);

	useEffect(() => {
		const { name, sort, 'cluster-ids': clusterIds, search } = pageParams;

		const dataTypes = piiFilterToArray(piiFilterWithGroups);

		const paramsForFx = {
			name,
			is_external: true,
			search,
			'data-types': dataTypes,
			sort: { orderBy: sort.value as keyof TAssetsItem, order: sort.operator },
			cluster_ids: clusterIds,
		};

		externalAssetsModel.fetchFx(paramsForFx);
	}, [pageParams]);

	// Clean up model on unmount
	useEffect(() => {
		return () => {
			externalAssetsModel.resetFx();
		};
	}, []);

	// That's Table filter, not filters in Filter line.
	function onFilterUpdate(property: keyof TAssetsItem, filterText: string) {
		setPageParams({ ...pageParams, name: filterText });
	}

	function onSortUpdate(property: keyof TAssetsItem) {
		const { sort } = state.params;

		const operator: TOrder = sort.orderBy === property && sort.order === 'asc' ? 'desc' : 'asc';
		const newParams = { sort: { operator, value: property } };

		setPageParams({ ...pageParams, ...newParams });
	}

	function onFilterLineUpdate(filterLineParams: Partial<typeof pageParams>) {
		setPageParams({ ...pageParams, ...filterLineParams });
	}

	function onUniversalSearchUpdate(search: string) {
		setPageParams({ ...pageParams, search });
	}

	function onResetFilters() {
		const newParams = { name: '', search: '', 'data-types': [], 'cluster-ids': [] };

		setPageParams({ ...pageParams, ...newParams });
		clearPiiFilter();
	}

	// Render

	const { status, data, total, total_filtered, hasMoreData, params } = state;

	const hasFilter =
		params.search.length > 0 ||
		(params['data-types'].length > 0 && params['data-types'][0] !== 'nonempty') ||
		params.name !== '' ||
		params.cluster_ids.length > 0;

	return (
		<SuperAssets>
			<Header>
				<Header.Breadcrumbs steps={dataCatalogSteps} finalStep="External connections" />

				<Header.Title>Service catalog</Header.Title>

				<Header.SensitiveSwitcher />

				<Header.MainButton
					to={PATHS.CUSTOM_EXTERNAL_CONNECTIONS_BUILDER}
					data-test="custom-asset-create-button"
				>
					New custom connection
				</Header.MainButton>
			</Header>

			<UniversalSearch value={params.search} onChange={onUniversalSearchUpdate} />

			<FilterLine
				config={['dataTypes', 'clustersExternal']}
				values={{
					dataTypes: pageParams['data-types'],
					clustersExternal: pageParams['cluster-ids'],
				}}
				onChange={(newValues) => {
					onFilterLineUpdate({ 'cluster-ids': newValues.clustersExternal });
				}}
			/>

			<FilterResult
				entityLabel="connection"
				loading={status === 'loading'}
				total={total}
				totalFiltered={total_filtered}
			/>

			<GridTable dataTest="external-assets-table">
				<EnhancedTableHead
					config={tableConfig}
					order={params.sort.order}
					orderBy={params.sort.orderBy}
					filterBy={{ name: params.name }}
					onRequestSort={onSortUpdate}
					onRequestFilter={onFilterUpdate}
					rowClassname={styles.rowContainer}
				/>

				<Preloader isLoading={status === 'initial' || status === 'loading'}>
					<GridBody data-test="external-assets-list">
						<>
							{data.length ? (
								data.map((externalAsset) => (
									<ExternalAssetTableRow
										externalAsset={externalAsset}
										search={params.search}
										key={externalAsset.id}
									/>
								))
							) : hasFilter ? (
								<ResetFilters onReset={onResetFilters} />
							) : (
								<NoContent type="externalAssetData" className={styles.rowContainer}>
									<NewGatewayBlock />
								</NoContent>
							)}

							<LoadMoreButton
								show={hasMoreData}
								loading={status === 'loadingMore'}
								request={externalAssetsModel.fetchMoreFx}
							/>
						</>
					</GridBody>
				</Preloader>
			</GridTable>
		</SuperAssets>
	);
}

export default ExternalAssets;
