import { createStore } from 'effector';
import { NoSQLDatabaseInstanceBase, NoSQLDatabaseInstanceList } from './dto';
import { getNoSQLDatabaseInstancesFx } from './effects';

const noSQLDatabaseInstancesStore = createStore<NoSQLDatabaseInstanceBase[]>([]);

noSQLDatabaseInstancesStore.on(
	getNoSQLDatabaseInstancesFx.doneData,
	(_, data: NoSQLDatabaseInstanceList) => {
		return data.instances.map(({ id, name, type, is_crawler_attached }) => {
			return {
				id,
				name,
				type,
				is_crawler_attached,
			};
		});
	}
);

const noSQLDatabaseInstancesById = noSQLDatabaseInstancesStore.map((instances) => {
	return instances.reduce(
		(acc, instance) => {
			acc[instance.id] = instance;

			return acc;
		},
		{} as {
			[key: NoSQLDatabaseInstanceBase['id']]: NoSQLDatabaseInstanceBase;
		}
	);
});

export { noSQLDatabaseInstancesStore, noSQLDatabaseInstancesById };
