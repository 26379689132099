import { get, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { AISessionsList, AIChat, AIChatContent, AIChatUpdate } from './dto';
import { aiChatExample, aiSessionsListExample } from './examples';

const API_URL = 'v1/ai-chats';

function getAISessionsList() {
	return get<AISessionsList>(API_URL).then(checkResponse(aiSessionsListExample));
}

function createAIChat(payload: AIChatContent) {
	return post<AIChat>(API_URL, payload).then(checkResponse(aiChatExample));
}

function getAIChat(sessionId: number, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<AIChat>(`${API_URL}/${sessionId}`, undefined, config).then(
		checkResponse(aiChatExample)
	);
}

function updateAIChat({ sessionId, ...payload }: AIChatUpdate) {
	return post<AIChat>(`${API_URL}/${sessionId}`, payload).then(checkResponse(aiChatExample));
}

export { getAISessionsList, createAIChat, getAIChat, updateAIChat };
