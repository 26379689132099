import cn from 'classnames';
import { generatePath } from 'react-router';
import { NavLink as RouterLink } from 'react-router-dom';
import LogoV2 from 'assets/icons/logoV2.svg';
import Icon from 'components/Icon';
import Typo from 'components/typography/Typo';
import { PATHS } from 'services/router';
import { debugSettings } from 'views/common/DebugOverlay';
import SensorsStatus from '../SensorsStatus';
import UserMenu from '../UserMenu';
import styles from './index.module.pcss';
import MatchRouterLink from './MatchRouterLink';

interface Props {
	collapsed: boolean;
	changeMenu: (collapse: boolean) => void;
}

function SideMenu({ collapsed, changeMenu }: Props) {
	return (
		<div className={cn(styles.container, { [styles.collapsed]: collapsed })}>
			<div className={styles.topSection}>
				<div className={styles.logoContainer}>
					<RouterLink to={PATHS.OVERVIEW} className={styles.logo} data-test="side-menu-logo-button">
						<Icon name="Soveren/Filled" />

						<span className={styles.label}>
							<LogoV2 />
						</span>
					</RouterLink>
				</div>

				<div className={styles.menu} data-test="side-menu-list">
					<MatchRouterLink
						collapsed={collapsed}
						icon="Home/Regular"
						activeIcon="Home/Filled"
						to={PATHS.OVERVIEW}
						data-test="side-menu-overview"
						label="Dashboard"
					/>

					<MatchRouterLink
						collapsed={collapsed}
						icon="Map/Regular"
						activeIcon="Map/Filled"
						to={PATHS.DATA_MAP}
						data-test="side-menu-data-map"
						label="Data map"
					/>

					<MatchRouterLink
						collapsed={collapsed}
						icon="DockRow/Regular"
						activeIcon="DockRow/Filled"
						to={PATHS.SERVICE_CATALOG}
						data-test="side-menu-assets"
						label="Service catalog"
					/>

					<MatchRouterLink
						collapsed={collapsed}
						icon="APIEndpoints/Regular"
						activeIcon="APIEndpoints/Filled"
						to={PATHS.API_ENDPOINTS}
						data-test="side-menu-api-endpoints"
						label="API endpoints"
					/>

					<MatchRouterLink
						collapsed={collapsed}
						icon="Database/Regular"
						activeIcon="Database/Filled"
						to={PATHS.DATA_STORAGES}
						data-test="side-menu-data-storages"
						label="Data stores"
					/>

					<MatchRouterLink
						collapsed={collapsed}
						icon="Pulse/Regular"
						activeIcon="Pulse/Filled"
						to={PATHS.EVENTS}
						data-test="side-menu-events"
						label="Activity log"
					/>

					<MatchRouterLink
						collapsed={collapsed}
						icon="ContactCard/Regular"
						activeIcon="ContactCard/Filled"
						to={PATHS.DATA_TYPES_LIST}
						data-test="side-menu-data-types"
						label="Data types"
					/>

					<div className={styles.separator} />

					<MatchRouterLink
						collapsed={collapsed}
						icon="Collections/Regular"
						activeIcon="Collections/Filled"
						to={PATHS.ASSET_GROUP_LIST}
						data-test="side-menu-groups"
						label="Groups"
					/>

					<MatchRouterLink
						collapsed={collapsed}
						icon="Shield/Regular"
						activeIcon="Shield/Filled"
						to={PATHS.POLICY_LIST}
						data-test="side-menu-policies"
						label="Policies"
					/>

					<MatchRouterLink
						collapsed={collapsed}
						icon="AppsAddIn/Regular"
						activeIcon="AppsAddIn/Filled"
						to={PATHS.INTEGRATIONS}
						data-test="side-menu-integrations"
						label="Integrations"
					/>

					<MatchRouterLink
						collapsed={collapsed}
						icon="PlugDisconnected/Regular"
						activeIcon="PlugDisconnected/Filled"
						to={PATHS.SENSORS}
						data-test="side-menu-sensors"
						label="Infrastructure access"
						indicator={<SensorsStatus />}
					/>

					<MatchRouterLink
						collapsed={collapsed}
						external
						icon="Info/Regular"
						activeIcon="Info/Regular"
						to="https://docs.soveren.io/en/stable/user-guide/overview/"
						data-test="side-menu-help"
						label="Help"
					/>
				</div>

				{debugSettings.FLAG_AI_ASSISTANCE && (
					<>
						<div className={styles.separator} />

						<MatchRouterLink
							collapsed={collapsed}
							icon="DataSunburst/Regular"
							activeIcon="DataSunburst/Filled"
							to={generatePath(PATHS.AI_COPILOT)}
							data-test="side-menu-ai-copilot"
							label="AI Copilot"
							beta
						/>
					</>
				)}
			</div>

			<div className={styles.bottomSection}>
				<UserMenu />

				<MatchRouterLink
					collapsed={collapsed}
					icon="PeopleTeam/Regular"
					activeIcon="PeopleTeam/Filled"
					to={generatePath(PATHS.TEAM)}
					data-test="user-menu-item-team"
					label="Team"
				/>

				<div className={styles.separator} />

				<Typo
					variant="D/Medium/Body-S"
					className={styles.menuItem}
					onClick={() => changeMenu(!collapsed)}
					data-test="side-menu-collapse-button"
				>
					<Icon
						className={styles.icon}
						name={collapsed ? 'ArrowExportLtr/Regular' : 'ArrowExportRtl/Regular'}
						size={20}
					/>

					<span className={styles.label}>Collapse</span>
				</Typo>
			</div>
		</div>
	);
}

export default SideMenu;
