import { createStore } from 'effector';
import { NamespaceLabels, Namespace } from './dto';
import { getNamespacesFx } from './effects';

const namespaceStore = createStore<NamespaceLabels>({
	labels: [],
	total: 0,
});

namespaceStore.on(
	getNamespacesFx.doneData,
	(state: NamespaceLabels, data: NamespaceLabels) => data
);

const namespaceList = namespaceStore.map(({ labels }) => {
	const nsList: Namespace[] = [];

	for (const label of labels) {
		const foundNs = nsList.find(
			(ns) => ns.name === label.namespace && ns.clusterId === label.cluster_id
		);

		if (foundNs) {
			foundNs.labels.push({ key: label.key, value: label.value });
		} else {
			nsList.push({
				name: label.namespace,
				clusterId: label.cluster_id,
				labels: [{ key: label.key, value: label.value }],
			});
		}
	}

	for (const ns of nsList) {
		ns.labels.sort((a, b) => a.key.localeCompare(b.key));
	}

	return nsList;
});

function sortedUniquesArray(items: string[]) {
	return Array.from(new Set(items)).sort((a, b) => a.localeCompare(b));
}

const namespaceLabelsList = namespaceStore.map(({ labels }) => {
	const labelList: { key: string; values: string[] }[] = [];

	for (const label of labels) {
		const foundLabel = labelList.find((l) => l.key === label.key);

		if (foundLabel) {
			foundLabel.values.push(label.value);
		} else {
			labelList.push({
				key: label.key,
				values: [label.value],
			});
		}
	}

	labelList.sort((a, b) => a.key.localeCompare(b.key));

	for (const l of labelList) {
		l.values = sortedUniquesArray(l.values);
	}

	return labelList;
});

const namespacesLoaded = namespaceStore.map((namespaces, oldNamespaces) => {
	return oldNamespaces !== undefined;
}); // Magical

export { namespaceList, namespaceLabelsList, namespacesLoaded };
