import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { ConfirmModal, getConfirmation } from 'components/ConfirmModal';
import Button from 'components/form/Button';
import Preloader from 'components/Preloader';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { deleteJiraIntegration } from 'models/integrations/jira/api';
import { getJiraSettingsFx } from 'models/integrations/jira/effects';
import { jiraSettingsStore } from 'models/integrations/jira/store';
import { PATHS } from 'services/router';
import integratonsStyles from '../index.module.css';
import JiraSVG from '../source/jira.svg';

function Jira() {
	const [isLoading, setLoading] = useState(true);
	const settings = useStore(jiraSettingsStore);

	const connected = settings.jira_api_token_is_set;

	useEffect(() => {
		getJiraSettingsFx().then(() => {
			setLoading(false);
		});
	}, []);

	async function confirmDisconnect() {
		const confirmResolveModal = (
			<ConfirmModal
				title="Disconnect Jira"
				confirmProps={{
					children: 'Disconnect',
				}}
			>
				If you disconnect Jira, you won’t be able to create Jira tickets from Soveren.
			</ConfirmModal>
		);

		return await getConfirmation(confirmResolveModal);
	}

	async function disconnect() {
		const confirmed = await confirmDisconnect();

		if (!confirmed) return;

		setLoading(true);
		await deleteJiraIntegration();
		await getJiraSettingsFx();
		setLoading(false);
	}

	const actionButtons = connected ? (
		<>
			<RouterLink to={PATHS.INTEGRATIONS_JIRA}>
				<Button size="small" color="secondary">
					Edit
				</Button>
			</RouterLink>

			<Button size="small" color="ghost" onClick={disconnect}>
				Disconnect
			</Button>
		</>
	) : (
		<RouterLink to={PATHS.INTEGRATIONS_JIRA}>
			<Button size="small" color="primary">
				Connect
			</Button>
		</RouterLink>
	);

	return (
		<div className={integratonsStyles.block}>
			<Preloader isLoading={isLoading}>
				<div className={integratonsStyles.top}>
					<JiraSVG />

					{connected && (
						<Typo variant="D/Medium/Body-S" className={integratonsStyles.connected}>
							Connected
						</Typo>
					)}
				</div>

				<div className={integratonsStyles.middle}>
					<div className={integratonsStyles.name}>
						<Typo variant="D/Medium/H100-Header">Jira</Typo>
					</div>

					<Typo variant="D/Regular/Body-S" className={integratonsStyles.description}>
						Create an incident in Jira.
					</Typo>
				</div>

				<div className={integratonsStyles.bottom}>{actionButtons}</div>
			</Preloader>
		</div>
	);
}

export default Jira;
