import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import Preloader from 'components/Preloader';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getDataTypesExtendedFx } from 'models/dataTypesExtended/effects';
import { dataTypesStructuredStore } from 'models/dataTypesExtended/store';
import { PATHS } from 'services/router';
import DataTypesTable from './DataTypesTable';

const newDataTypePath = generatePath(PATHS.DATA_TYPES_ITEM, { id: 'new' });

export function DataTypesList() {
	const [isLoading, setLoading] = useState(true);
	const tableData = useStore(dataTypesStructuredStore);

	useEffect(() => {
		getDataTypesExtendedFx().then(() => setLoading(false)); // Refresh data each time we get on this page.
	}, []);

	return (
		<>
			<Header>
				<Header.Breadcrumbs finalStep="Data types" />

				<Header.Title>Data types</Header.Title>

				<Header.MainButton to={newDataTypePath} data-test="data-types-header-create-button">
					New custom data type
				</Header.MainButton>
			</Header>

			<Preloader isLoading={isLoading}>
				<DataTypesTable data={tableData} />
			</Preloader>
		</>
	);
}
