import { createEvent, createStore } from 'effector';
import { produce } from 'immer';
import { AssetJson } from 'models/assets/dto';
import { S3RegionItem } from 'models/s3Regions/dto';
import dropSession from 'services/dropSession';
import { MapAsset } from '../../index';

// Important: this store must have no knowledge about cubes and rags, since they are
// transient, and store values must persist between switching modes (e.g. group / namespace switch).

type SearchModel = {
	searchString: string;
	assetId: null | AssetJson['id'];
};

type FilterModel = {
	dataTypes: number[];
	namespaces: string[];
	labelKeys: string[];
	labelValues: string[];
	groups: number[];
	clusters: number[];
	regions: S3RegionItem['keyword'][];
	resourceTypes: { id: string; name: string }[];
};

type DataMapTypes =
	| 'asset'
	| 'group'
	| 's3_bucket'
	| 'kafka_instance'
	| 'sql_db_database'
	| 'nosql_db_database';

type SelectModel =
	| null
	| {
			type: DataMapTypes;
			id: MapAsset['elementId'] | number; // 'number' is a reminder that it's not JUST elementId, but can be smth else
	  }
	| {
			type: 'namespace';
			name: AssetJson['namespace'];
			clusterId: AssetJson['cluster_id']; // This is sketchy. Not robust enough for multi-level hierarchy (vpc, region etc).
	  };

type InteractsWithModel = {
	hovered: {
		type: DataMapTypes;
		id: number;
	} | null;
	selected: {
		type: DataMapTypes;
		id: number;
	} | null;
};

type GroupByNamespaceOrGroup = 'namespace' | 'customGroup';
type GroupByRegionOrCluster = 'cluster' | 'region';

type Store = {
	search: SearchModel;
	filter: FilterModel;
	selected: SelectModel;
	interactsWith: InteractsWithModel;
	groupByNamespaceOrGroup: GroupByNamespaceOrGroup;
	groupByRegionOrCluster: GroupByRegionOrCluster;
	showAssetSensitivity: boolean;
	curtainClosed: boolean;
};

const STORAGE_KEY = 'svrn-map-controls';

// Initialize store with defaults or load from localStorage
const initialState: Store = {
	search: {
		searchString: '',
		assetId: null,
	},
	filter: {
		dataTypes: [],
		namespaces: [],
		labelKeys: [],
		labelValues: [],
		groups: [],
		clusters: [],
		regions: [],
		resourceTypes: [],
	},
	selected: null,
	interactsWith: { hovered: null, selected: null },
	groupByNamespaceOrGroup: 'namespace',
	groupByRegionOrCluster: 'region',
	showAssetSensitivity: false,
	curtainClosed: false,
};
const savedState = localStorage.getItem(STORAGE_KEY);
const mapControlsStore = createStore<Store>(savedState ? JSON.parse(savedState) : initialState);

// Manage store
// We rely on event caller to correctly handle mutable/immutable issues.
const changeMapControls = createEvent<Store>();
mapControlsStore.on(changeMapControls, (state: Store, payload: Store) => {
	return payload;
});

const unselectInteractsWith = createEvent();
mapControlsStore.on(unselectInteractsWith, (state: Store) =>
	produce(state, (draft) => {
		draft.interactsWith.selected = null;
	})
);

// Save to localstorage
mapControlsStore.watch((state) => localStorage.setItem(STORAGE_KEY, JSON.stringify(state)));

// Clear on logout (otherwise, bugs appear because different clients have different asset IDs)
dropSession.watch(() => changeMapControls(initialState));

// For bug-fixing
function _purgeStoreIfNonDefault() {
	if (mapControlsStore.getState() === initialState) return;

	localStorage.removeItem(STORAGE_KEY);
	window.location.reload();
}

export { mapControlsStore, changeMapControls, unselectInteractsWith, _purgeStoreIfNonDefault };
export type {
	GroupByRegionOrCluster,
	GroupByNamespaceOrGroup,
	SelectModel,
	SearchModel,
	DataMapTypes,
};
