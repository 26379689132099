import { getDataTypesAsApiParam } from 'models/dataTypes/helpers';
import { createTableModel } from 'models/modelUtils/tableModelFactory';
import { ApiParams } from 'services/api';
import {
	getNoSQLDatabaseList,
	getNoSQLDatabaseCollectionList,
	getNoSQLDatabaseSchemasList,
	getNoSQLDatabaseSamplesList,
} from './api';
import {
	NoSQLDatabaseCollectionItem,
	NoSQLDatabaseTableParams,
	NoSQLDatabaseCollectionsTableParams,
	NoSQLDatabaseItem,
	NoSQLDatabaseSchemasTableParams,
	NoSQLDatabaseSchemaItem,
	NoSQLDatabaseSamplesTableParams,
	NoSQLDatabaseSampleItem,
} from './dto';

/* Databases */
function fetchNoSQLDatabasesApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { 'data-types': piiTypes, ...apiParams } = apiParamsRaw;

	apiParams['data-types'] = getDataTypesAsApiParam(piiTypes);

	return getNoSQLDatabaseList(apiParams, signal).then((payload) => {
		return {
			data: payload.databases,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createNoSQLDatabasesModel(
	initialParams: NoSQLDatabaseTableParams,
	fetchFxOnInit: boolean = true
) {
	return createTableModel<NoSQLDatabaseItem, NoSQLDatabaseTableParams>(
		initialParams,
		fetchNoSQLDatabasesApi,
		fetchFxOnInit
	);
}

const initialParams: NoSQLDatabaseTableParams = {
	'data-types': [],
	search: '',
	sort: { orderBy: 'name', order: 'desc' },
};

const noSQLDatabasesModel = createNoSQLDatabasesModel(initialParams, false);

/* Collections */
function fetchNoSQLCollectionsTablesApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { id, 'data-types': piiTypes, ...apiParams } = apiParamsRaw;

	apiParams['data-types'] = getDataTypesAsApiParam(piiTypes);

	return getNoSQLDatabaseCollectionList(Number(id), apiParams, signal).then((payload) => {
		return {
			data: payload.collections,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createNoSQLDatabaseCollectionsModel(
	initialDatabaseTablesParams: NoSQLDatabaseCollectionsTableParams,
	fetchFxOnInit: boolean = true
) {
	return createTableModel<NoSQLDatabaseCollectionItem, NoSQLDatabaseCollectionsTableParams>(
		initialDatabaseTablesParams,
		fetchNoSQLCollectionsTablesApi,
		fetchFxOnInit
	);
}

const initialNoSQLDatabaseCollectionsParams: NoSQLDatabaseCollectionsTableParams = {
	id: -1,
	'data-types': [],
	search: '',
	sort: { orderBy: 'name', order: 'desc' },
};

const noSQLDatabaseCollectionsModel = createNoSQLDatabaseCollectionsModel(
	initialNoSQLDatabaseCollectionsParams,
	false
);

/* Schemas */
function fetchNoSQLDatabaseSchemasTablesApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { id, 'data-types': piiTypes, ...apiParams } = apiParamsRaw;

	apiParams['data-types'] = getDataTypesAsApiParam(piiTypes);

	return getNoSQLDatabaseSchemasList(Number(id), apiParams, signal).then((payload) => {
		return {
			data: payload.schemas,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createNoSQLDatabaseSchemasModel(
	initialNoSQLDatabaseSchemasParams: NoSQLDatabaseSchemasTableParams,
	fetchFxOnInit: boolean = true
) {
	return createTableModel<NoSQLDatabaseSchemaItem, NoSQLDatabaseSchemasTableParams>(
		initialNoSQLDatabaseSchemasParams,
		fetchNoSQLDatabaseSchemasTablesApi,
		fetchFxOnInit
	);
}

const initialNoSQLDatabaseSchemasParams: NoSQLDatabaseSchemasTableParams = {
	id: -1,
	'data-types': [],
	sort: { orderBy: 'name', order: 'desc' },
};

const noSQLDatabaseSchemasModel = createNoSQLDatabaseSchemasModel(
	initialNoSQLDatabaseSchemasParams,
	false
);

/* Samples */
function fetchNoSQLDatabaseSampleApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { id, 'data-types': piiTypes, ...apiParams } = apiParamsRaw;

	apiParams['data-types'] = getDataTypesAsApiParam(piiTypes);

	return getNoSQLDatabaseSamplesList(Number(id), apiParams, signal).then((payload) => {
		return {
			data: payload.samples,
			total: payload.total,
		};
	});
}

function createNoSQLDatabaseSamplesModel(
	initialParamsSamples: NoSQLDatabaseSamplesTableParams,
	fetchFxOnInit: boolean = true
) {
	return createTableModel<NoSQLDatabaseSampleItem, NoSQLDatabaseSamplesTableParams>(
		initialParamsSamples,
		fetchNoSQLDatabaseSampleApi,
		fetchFxOnInit
	);
}

const initialParamsSamples: NoSQLDatabaseSamplesTableParams = {
	id: -1,
	'data-types': [],
	sort: { orderBy: 'object_id', order: 'desc' },
};

const noSQLDatabaseSamplesModel = createNoSQLDatabaseSamplesModel(initialParamsSamples, false);

export {
	noSQLDatabasesModel,
	noSQLDatabaseCollectionsModel,
	noSQLDatabaseSchemasModel,
	noSQLDatabaseSamplesModel,
};
