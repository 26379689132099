import { createEffect } from 'effector';
import { createAIChat, getAIChat, getAISessionsList, updateAIChat } from './api';
import { AIChat, AIChatContent, AIChatUpdate, AISessionsList } from './dto';

let ac = new AbortController();

/* API */

const getAISessionsListFx = createEffect<void, AISessionsList>();
const createAIChatFx = createEffect<AIChatContent, AIChat>();
const getAIChatFx = createEffect<{ sessionId: number; abortSignal?: boolean }, AIChat>();
const updateAIChatFx = createEffect<AIChatUpdate, AIChat>();
/* IMPLEMENTATION */

// Simple direct-to-api effects

getAISessionsListFx.use(getAISessionsList);
createAIChatFx.use(createAIChat);

getAIChatFx.use((payload: { sessionId: number; abortSignal?: boolean }) => {
	if (payload.abortSignal) {
		// Max one request at a time. Since API is parametrized - just cancel previous request(s).
		ac.abort();
		ac = new AbortController();
	}

	return getAIChat(payload.sessionId, ac.signal);
});

updateAIChatFx.use(updateAIChat);

// Watchers
createAIChatFx.doneData.watch(() => getAISessionsListFx());

export { getAISessionsListFx, createAIChatFx, getAIChatFx, updateAIChatFx };
