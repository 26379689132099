import { generatePath } from 'react-router';
import ExternalLink from 'components/typography/ExternalLink';
import { s3BucketsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { S3Sample } from 'models/s3Sample/dto';
import { PATHS } from 'services/router';
import s3BucketHeaderStyles from '../S3BucketItemView/S3BucketHeader/index.module.pcss';

type Props = {
	sample: Pick<
		S3Sample,
		| 'cloud_console_url'
		| 'bucket_id'
		| 'bucket_name'
		| 'cluster_id'
		| 'cluster_name'
		| 'name'
		| 'type'
	> | null;
};

function S3SampleHeader({ sample }: Props) {
	if (!sample) return null;

	const { cloud_console_url, bucket_id, bucket_name, cluster_id, cluster_name, name, type } =
		sample;
	const showSensitiveSwitcher = type === 'csv' || type === 'spreadsheet';

	const steps = [
		...s3BucketsSteps,
		{
			path: generatePath(PATHS.S3_BUCKETS_ITEM, {
				id: bucket_id,
				tab: 'summary',
			}),
			name: bucket_name,
		},
		{
			path: generatePath(PATHS.S3_BUCKETS_ITEM, {
				id: bucket_id,
				tab: 'files',
			}),
			name: 'Files',
		},
		{
			path: generatePath(PATHS.S3_CLUSTER_ITEM, {
				id: bucket_id,
				clusterId: cluster_id,
			}),
			name: cluster_name,
		},
	];

	return (
		<Header>
			<Header.Breadcrumbs steps={steps} finalStep={name} />

			<Header.Title>{name}</Header.Title>

			{cloud_console_url && (
				<Header.CustomAction>
					<ExternalLink href={cloud_console_url} className={s3BucketHeaderStyles.link}>
						View in cloud console
					</ExternalLink>
				</Header.CustomAction>
			)}

			{showSensitiveSwitcher && <Header.SensitiveSwitcher />}
		</Header>
	);
}

export { S3SampleHeader };
