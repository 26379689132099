import { useMemo, useState } from 'react';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import { enqueueSnackbar } from 'components/Snackbar';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { DatabaseDetails } from 'models/databases/dto';
import { updateNoSQLDatabaseDetails } from 'models/noSQLDatabases/api';
import { NoSQLDatabaseDetails, NoSQLDatabaseInstanceBase } from 'models/noSQLDatabases/dto';
import { APIError } from 'services/api/httpRequest';
import { lastSeen } from 'services/lastSeen';
import { toFileSize, toLocaleString } from 'services/numbers';
import { AutomaticDiscoveryNonAvailableData } from 'views/common/AutomaticDiscovery';
import { EditableInput } from 'views/common/EditableInput';
import styles from '../index.module.css';

type Props = {
	db: NoSQLDatabaseDetails;
	instance: NoSQLDatabaseInstanceBase;
};

function Summary({ db, instance }: Readonly<Props>) {
	const [database, setDatabase] = useState(db);
	const { collections_count, size, sensitivity, data_types, last_checked, owner, description } =
		database;

	const lastCheckedValue = useMemo(
		() => lastSeen({ timestamp: last_checked, short: true }),
		[last_checked]
	);

	const onSave = async (parameter: keyof DatabaseDetails, value: string) => {
		if (value.length > 200) {
			return 'The description is more than 200 symbols.';
		}

		try {
			const updated = await updateNoSQLDatabaseDetails({ ...db, [parameter]: value });
			setDatabase({ ...db, ...updated });
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();
				return message;
			}

			const errorMessage = 'Something went wrong';
			enqueueSnackbar(errorMessage);
			return errorMessage;
		}

		return null;
	};

	return (
		<div className={styles.detailsGrid} data-test="database-detail-container">
			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				Description
			</Typo>

			<EditableInput
				dataTest="database-detail-description"
				value={description}
				onSave={(value: string) => onSave('description', value)}
				className={styles.editableInput}
				placeholder="Enter description"
				multiline
			/>

			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				Owner
			</Typo>
			<EditableInput
				value={owner}
				onSave={(value: string) => onSave('owner', value)}
				className={styles.editableInput}
				placeholder="Enter owner’s name"
				dataTest="database-detail-owner"
			/>

			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				Collections
			</Typo>
			{instance.is_crawler_attached ? (
				<Typo variant="D/Regular/Body-S" data-test="database-detail-tables-count">
					{toLocaleString(collections_count)}
				</Typo>
			) : (
				<AutomaticDiscoveryNonAvailableData data-test="database-detail-tables-count" />
			)}

			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				Size
			</Typo>
			{instance.is_crawler_attached ? (
				<Typo variant="D/Regular/Body-S" data-test="database-detail-size">
					{toFileSize(size)}
				</Typo>
			) : (
				<AutomaticDiscoveryNonAvailableData data-test="database-detail-size" />
			)}

			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				Sensitivity
			</Typo>
			<Typo variant="D/Regular/Body-S" data-test="database-detail-sensitivity">
				<SensitivityChip sensitivity={sensitivity} />
			</Typo>

			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				Data types
			</Typo>
			{instance.is_crawler_attached ? (
				<PiiTypeList data={data_types} noHighlight dataTest="database-detail-data-types" />
			) : (
				<AutomaticDiscoveryNonAvailableData data-test="database-detail-data-types" />
			)}

			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				Instance
			</Typo>
			<Typo
				variant="D/Regular/Body-S"
				className={styles.cellWithButton}
				data-test="database-detail-instance-name"
			>
				{instance.name}
			</Typo>

			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				Resource type
			</Typo>
			<Typo
				variant="D/Regular/Body-S"
				className={styles.type}
				data-test="database-detail-instance-type"
			>
				{instance.type}
			</Typo>

			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				Last checked
			</Typo>
			<Typo variant="D/Regular/Body-S" data-test="database-detail-last-checked">
				<Tooltip title={lastCheckedValue.date} className={styles.detailsValue}>
					{lastCheckedValue.diff}
				</Tooltip>
			</Typo>
		</div>
	);
}

export { Summary };
