import Typo from 'components/typography/Typo';
import { endpointsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header, { TitleProps } from 'layouts/AuthorizedWithLeftMenu/Header';
import { TitleBlocks, TitleBlockProps } from '../SampleHeader';
import styles from './index.module.css';

interface StateProps {
	state: TitleBlockProps | null;
	id: string;
}

function WashedSample({ state, id }: StateProps) {
	let title: TitleProps['children'], subtitle: TitleProps['children'];

	if (state) {
		[title, subtitle] = TitleBlocks({ asset: state.asset, sample: state.sample });
	} else {
		title = `Data sample with ID ${id}`;
		subtitle = '';
	}

	return (
		<>
			<Header>
				<Header.Breadcrumbs steps={endpointsSteps} finalStep="Sample" />

				<Header.Title>{title}</Header.Title>
				{subtitle && <Header.Subtitle>{subtitle}</Header.Subtitle>}
			</Header>

			<Typo variant="D/Regular/Body-S" color="secondary" className={styles.noSample}>
				No current data sample available, as there has been no
				<br /> traffic to this API endpoint in the last 7 days.
			</Typo>
		</>
	);
}
export { WashedSample };
